


import { SearchRounded } from '@mui/icons-material'
import { Box, FormControl, FormLabel, Input, Option, Select, Stack } from '@mui/joy'
import React, { useEffect, useState } from 'react'

const ListFilterView = ({table, search, setSearch}) => {

  return (
    <Box
      className="SearchAndFilters-tabletUp"
      sx={{
        borderRadius: 'sm',
        pb: 2,
        display: { xs: 'none', sm: 'flex' },
        flexWrap: 'wrap',
        gap: 1.5,
        '& > *': {
          minWidth: { xs: '120px', md: '160px' },
        },
      }}
    >
      <FormControl sx={{ flex: 1 }} size="sm">
        <FormLabel>Search for store</FormLabel>
        <Input size="sm" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} startDecorator={<SearchRounded />} />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Active Status</FormLabel>
        <Select
          size="sm"
          placeholder="All"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        >
          <Option value="paid">All</Option>
          <Option value="pending">Active</Option>
          <Option value="refunded">Inactive</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>User Role</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">Admin</Option>
          <Option value="refund">Manager</Option>
        </Select>
      </FormControl>
    </Box>
  )
}

export default ListFilterView