

import { Stack } from '@mui/joy'
import React from 'react'
import { DistributorList } from '../../components/Distributor'
import Text from '../../components/Text'

const DistributorPage = () => {
  return (
    <Stack p={3}>
        <Text level="h3" pb={2.5}>Distributors</Text>
        <DistributorList />
    </Stack>
  )
}

export default DistributorPage