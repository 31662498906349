



import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useGetBrandQuery, useGetStoreSkuActivityQuery, useUpdateSkuLabelMutation } from '../store/query'

const useSkuImageActivityForm = (data) => {

    const activityList = useGetStoreSkuActivityQuery()
    const brand = useGetBrandQuery()
    const [updateActivity] = useUpdateSkuLabelMutation()

    const [index, setIndex] = useState(0)
    const [activity, setActivity] = useState(null)

    const hasActivity = useMemo(() => activityList.data?.length > 0, [activityList.data])

    const form = useForm({
        values: {
            id : activity?.id,
            ...activity,
            mappedBrands: activity?.images?.map(item => ({ image_url: item, 
                // brand_ids: [], 
                brand_quant_info: [] })) ?? []
        }
    })

    const fields = useFieldArray({
        control : form.control,
        name : "mappedBrands"
    })

    const resetForm = () => {
    }

    const submit = form.handleSubmit((data) => {
        // if (index < activity.images.length - 1) {
        //     setIndex(index + 1)
        // } else {
            setIndex(0)
            // console.log("data", data);
            // console.log("data", data);
            updateActivity(data)
        // }
    })

    useEffect(() => {
        if (activityList.data) {
            setActivity(activityList.data[0])
        }
    }, [activityList.data])

    return {
        ...form,
        index,
        isLoading: activityList.isLoading,
        brand,
        hasActivity,
        data : activity,
        images : fields,
        setIndex,
        submit
    }
}

export default useSkuImageActivityForm