



import { Button, Stack, Table } from '@mui/joy'
import moment from 'moment'
import React, { useRef } from 'react'
import Papa from "papaparse"


const BizomToFairconnectCsvConverterPage = () => {
    const inputRef = useRef()
    const converUserMaster = async(event) => {
        try {
            const file = event.target.files[0]
            Papa.parse(file, {
                delimiter: ",",
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    if(results.errors.length > 0){
                        console.log("resdadad", results.errors);
                        // reject(new Error("error parsing csv", results.errors))
                        return
                    }
                    const data = results.data;
                    const mapData = data?.map(row => {
                        return {
                            "Employeeid" : row["ID"],
                            "UserName*" : row["Username"],
                            "Password" : "password",
                            "Name of User*" : row["Name"],
                            "Mobile number*" : row["Phone no"],
                            "Email id*" : row["Email"],
                            "Designation*" : row["Designation"],
                            "Role (Bizom Equivalent)*" : row["Role"],
                            "Reporting manager name*" : row["Reporting"],
                            "Head Quarter*" : row["For"]
                        }
                    })
                    const csvString = Papa.unparse(mapData, { delimiter: "," });
                    const blob = new Blob([csvString], { type: "text/csv" })
                    const csv = window.URL.createObjectURL(blob)
                    window.open(csv, "_blank")
                }
            })
            // const data = await file.text()
            // const line = data.split("\n")
            // const header = `"Employeeid","Name of User*","UserName*","","","Email id*","Mobile number*","Address","Credit limit","Balance","Designation*","Role (Bizom Equivalent)*","ZONE","WAREHOUSE","Head Quarter*","Status","InActive Date","Created","Modified","Reporting manager name*","Reporting To Status"`
            // line[0] = header
            // const convertedData = line.join("\n")
            // const blob = new Blob([convertedData], {type: "text/csv"})
            // const csv = window.URL.createObjectURL(blob)
            // window.open(csv, "_blank")
        } catch (error) {
            console.log("error", error);
        }
    }
    const converBeatMaster = async(event) => {
        try {
            const file = event.target.files[0]
            Papa.parse(file, {
                delimiter: ",",
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    if(results.errors.length > 0){
                        console.log("resdadad", results.errors);
                        // reject(new Error("error parsing csv", results.errors))
                        return
                    }
                    const data = results.data;
                    const mapData = data?.map(row => {
                        return {
                            "Beat_ID" : row["Beat_ID"],
                            "Beat name" : row["BeatName"],
                            "erpid" : row["erpid"],
                            "Total_no_Of_outlets" : row["Total_no_Of_outlets"],
                            "Distributor" : row["Distributor"],
                            "Subzone" : row["Subzone"],
                            "Zone" : row["Zone"],
                            "User_ID" : row["User_ID"],
                            "User_Name" : row["User_Name"],
                            "User name(login ID)" : row["Login_Username"],
                            "Distributor ERP Code" : row["Distributor ERP Code"],
                            // "Beat_Created_Date" : row["Beat_Created_Date"],
                            "Reporting_Manager" : row["Reporting_Manager"],
                            "Reporting_Manager_ID" : row["Reporting_Manager_ID"],
                            "Warehouse_label" : row["Warehouse_label"],
                            "Distributor_Jurisdiction" : row["Distributor_Jurisdiction"],
                            "PJP_Frequency" : row["PJP_Frequency"],
                            "Circle" : row["Circle"],
                            "Beat_Created_By" : row["Beat_Created_By"],
                        }
                    })
                    const csvString = Papa.unparse(mapData, { delimiter: "," });
                    const blob = new Blob([csvString], { type: "text/csv" })
                    const csv = window.URL.createObjectURL(blob)
                    window.open(csv, "_blank")
                }
            })
            // const data = await file.text()
            // const line = data.split("\n")
            // const header = `"Employeeid","Name of User*","UserName*","","","Email id*","Mobile number*","Address","Credit limit","Balance","Designation*","Role (Bizom Equivalent)*","ZONE","WAREHOUSE","Head Quarter*","Status","InActive Date","Created","Modified","Reporting manager name*","Reporting To Status"`
            // line[0] = header
            // const convertedData = line.join("\n")
            // const blob = new Blob([convertedData], {type: "text/csv"})
            // const csv = window.URL.createObjectURL(blob)
            // window.open(csv, "_blank")
        } catch (error) {
            console.log("error", error);
        }
    }
    const converOutletMaster = async(event) => {
        try {
            const file = event.target.files[0]
            Papa.parse(file, {
                delimiter: ",",
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    if(results.errors.length > 0){
                        console.log("resdadad", results.errors);
                        // reject(new Error("error parsing csv", results.errors))
                        return
                    }
                    const data = results.data;
                    const mapData = data?.map(row => {
                        return {
                            "OutletId" : row["OutletId"],
                            "Outlet Name" : row["Outlet Name"],
                            "Subcategory" : row["Subcategory"],
                            "ERP_ID" : row["ERP_ID"],
                            "Outlet_Status" : row["Outlet_Status"],
                            "Address" : row["Address"],
                            "Outlet_Latitude" : row["Outlet_Latitude"],
                            "Outlet_Longitude" : row["Outlet_Longitude"],
                            "Phone Number" : row["Phone Number"],
                            "Owner Name" : row["Owner Name"],
                            "Owner Mobile" : row["Owner Mobile"],
                            "Email" : row["Email"],
                            "Pin Code" : row["Pin Code"],
                            "Business_Type" : row["Business_Type"],
                            "Outlet Type" : row["Outlet Type"],
                            "Parent_Outlet_Id" : row["Parent_Outlet_Id"],
                            "Beat Name" : row["Beat Name"],
                            "Beat_Erp_ID" : row["Beat_Erp_ID"],
                            "Beat ID" : row["Beat ID"],
                            "Status" : row["Status"],
                            "Is_Phone_no_Verified" : row["Is_Phone_no_Verified"],
                            "Area" : row["Area"],
                            "Area_Erp_ID" : row["Area_Erp_ID"],
                            "Area_ID" : row["Area_ID"],
                            "State_Id" : row["State_Id"],
                            "State_Name" : row["State_Name"],
                            "District_Id" : row["District_Id"],
                            "District_Name" : row["District_Name"],
                            "City_Id" : row["City_Id"],
                            "City_Name" : row["City_Name"],
                            "DISTRIBUTOR" : row["DISTRIBUTOR"],
                            "Distributor_Erp_ID" : row["Distributor_Erp_ID"],
                            "Distributor_Id" : row["Distributor_Id"],
                            "Warehousetype" : row["Warehousetype"],
                            "Subzone" : row["Subzone"],
                            "ZONE" : row["ZONE"],
                            "Created Date" : row["Created Date"],
                            "Created By" : row["Created By"],
                            "User_id" : row["User_id"],
                            "Name" : row["Name"],
                            "Username" : row["Username"],
                            "COC / ROC" : row["COC / ROC"],
                            "MT / PR Chain Name" : row["MT / PR Chain Name"],
                            "Category" : row["Category"],
                            "Chain Name" : row["Chain Name"],
                            "Scheme Type" : row["Scheme Type"],
                            "Service Type" : row["Service Type"],
                            "Classification" : row["Classification"],
                            "Modified_Date" : row["Modified_Date"],
                            "Depo_Code" : row["Depo_Code"],
                            "Depo_Name" : row["Depo_Name"],
                            "Outlet_channel" : row["Outlet_channel"],
                            "Last_Productive_date" : row["Last_Productive_date"],
                            "Last_Visit_date" : row["Last_Visit_date"],
                            "Employee_Code" : row["Employee_Code"],
                            "Payee_Name" : row["Payee_Name"],
                            "Payee_Code" : row["Payee_Code"],
                            "Visit_Frequency" : row["Visit_Frequency"],
                        }
                    })
                    const csvString = Papa.unparse(mapData, { delimiter: "," });
                    const blob = new Blob([csvString], { type: "text/csv" })
                    const csv = window.URL.createObjectURL(blob)
                    window.open(csv, "_blank")
                }
            })
            // const data = await file.text()
            // const line = data.split("\n")
            // const header = `"Employeeid","Name of User*","UserName*","","","Email id*","Mobile number*","Address","Credit limit","Balance","Designation*","Role (Bizom Equivalent)*","ZONE","WAREHOUSE","Head Quarter*","Status","InActive Date","Created","Modified","Reporting manager name*","Reporting To Status"`
            // line[0] = header
            // const convertedData = line.join("\n")
            // const blob = new Blob([convertedData], {type: "text/csv"})
            // const csv = window.URL.createObjectURL(blob)
            // window.open(csv, "_blank")
        } catch (error) {
            console.log("error", error);
        }
    }
  return (
    <Stack>
        <Table>
            <div>
            </div>
            <tbody>
                <tr>
                    <td>User Map</td>
                    <td>
                        <input type='file' onChange={converUserMaster} ref={inputRef} />
                    </td>
                </tr>
                <tr>
                    <td>Beat Master Converter</td>
                    <td>
                        <input type='file' onChange={converBeatMaster} ref={inputRef} />
                    </td>
                </tr>
                <tr>
                    <td>Outlet Master Converter</td>
                    <td>
                        <input type='file' onChange={converOutletMaster} ref={inputRef} />
                    </td>
                </tr>
            </tbody>
        </Table>
    </Stack>
  )
}

export default BizomToFairconnectCsvConverterPage