

import React, { useMemo, useState } from 'react'
import Table from '../Table'
import Text from '../Text'
import { Button, Checkbox, Chip, IconButton, Input, Option, Select, Stack } from '@mui/joy'
import { createColumnHelper } from '@tanstack/react-table'
import { CloseRounded, MoreHorizRounded, SearchRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { renderMenu } from '../ContextMenu'
import Link from '../Link'
import { useGetOrderListQuery } from '../../store/query'
import CustomTable from '../Table/CustomTable'
import moment from 'moment'
import { parseTimeArray } from '../../utils'
import { SelectStore } from '../Store'
import { SelectDateRange } from '../Selection'
import FilterChip from '../Table/FilterChip'

const columnHelper = createColumnHelper()

const ListView = () => {

    const app = useAppContext()

    const getDataFromQuery = response => response?.data
    const getEncodedFilter = filter => {
        let string = "", expression = ""
        if(filter.currentPage) string = `&page=${filter.currentPage}`
        if(filter.search) expression += `name==${filter.search};`
        return string + (expression ? "&filterExpression="+expression : "")
    }
    const getTotalPages = response => {
        return response?.pagination.totalPages
    }

    const onRowClicked = (event, row) => {
        const contextMenuData = [
            {
                id : 1,
                title : "Edit beat",
                onClick : () => app.editUserRoleModalRef?.current?.open(row)
            },
            {
                id : 1,
                title : "Add/Remove Store"
            },
            {
                id : 1,
                title : "Add/Remove Store"
            },
            {
                id : 1,
                title : "Deactivate"
            }
        ]
        app.contextMenuRef?.current?.open({
            data : renderMenu(contextMenuData),
            top: event.clientY + 1,
            left : event.clientX + 1,
        })
    }

    return(
        <CustomTable
                name='MasterOrderTable'
                query={useGetOrderListQuery}
                getDataFromQuery={getDataFromQuery}
                filter={{param: 12345}}
                getEncodedFilter={getEncodedFilter}
                getTotalPages={getTotalPages}
                // totalPage={order.data?.pagination?.totalPages}
                HeaderComponent={ListHeaderComponent}
                renderItem={ListItemView}
            />
    )
}




export const ListHeaderComponent = (props) => {
    const filter = useMemo(() => {
        const filter = []
        filter.push({label: "Today", data: props.filter.date})
        props.filter.outlet?.forEach(item => filter.push({label: item.name, data: item}))
        return filter
    }, [])
    const onFilterItemRemove = () => {

    }
    return (
        <Stack>
            {/* <Stack pb={3}>
                <Text level='title-md' pb={1}>Filter</Text>
                <Stack flexDirection={"row"} gap={2}>
                    <SelectDateRange />
                    <SelectStore />
                    <Stack flex={1} />
                    <Stack flex={1} />
                    <Stack flex={1} />
                    <Stack flex={1} /> */}
                    {/* <Select placeholder="User" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} /> */}
                {/* </Stack>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <FilterChip filter={props.filter} handleFilterChange={props.handleFilterChange} />
                </Stack>
            </Stack> */}
            <Stack pb={2}>
                <Text level='title-md' pb={0.4}>All List</Text>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <Input
                        startDecorator={<SearchRounded />}
                        sx={{flex: 1, maxWidth: 1000}}
                        />
                    <Stack flex={1} />
                    <Button>Download</Button>
                </Stack>
            </Stack>
            <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} borderTop={"1px solid #ededed"} sx={{backgroundColor: "#F7F8FA"}}>
                <Stack p={1} px={2} sx={{width: 20}}>
                    <Stack>
                        <Checkbox size='sm' />
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Order Info</Text>
                    </Stack>
                </Stack>
                <Stack p={1} flex={1}>
                    <Stack>
                        <Text level='title-sm'>Outlet</Text>
                    </Stack>
                </Stack>
                <Stack p={1} flex={1}>
                    <Stack>
                        <Text level='title-sm'>Fulfilled By</Text>
                    </Stack>
                </Stack>
                <Stack p={1} flex={1}>
                    <Stack>
                        <Text level='title-sm'>Status</Text>
                    </Stack>
                </Stack>
                <Stack p={1} px={2} flex={1}>
                    <Stack flex={1}>
                        <Text level='title-sm'>Product</Text>
                    </Stack>
                </Stack>
                <Stack p={1} px={3} flex={1} alignItems={'end'}>
                    <Stack>
                        <Text level='title-sm'>Amount</Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
  }


export const ListItemView = (item, index) => {
    return (
      <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} backgroundColor="#fff">
        <Stack p={2} sx={{width: 20}}>
            <Stack>
                <Checkbox size='sm' />
            </Stack>
        </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack>
                    <Link to={`/order/${item?.id}`}>
                        <Text level='title-sm' pl={0.4}>#{item?.id}</Text>
                    </Link>
                  <Text level='body-sm'>{parseTimeArray(item?.createdAt, null, true).format("DD MMM YYYY")}</Text>
              </Stack>
          </Stack>
          <Stack p={1} flex={1}>
              <Stack>
                <Link to={`/store/${item?.outlet?.id}`}>
                  <Text level='title-sm'>{item?.outlet?.name}</Text>
                </Link>
                  {/* <Text level='body-sm'>Cyber Beat, Haryana</Text> */}
              </Stack>
          </Stack>
          <Stack p={1} flex={1}>
              {/* <Stack>
                  <Text level='title-sm'>JV Distributor</Text>
                  <Text level='body-sm'>Aman Gupta</Text>
              </Stack> */}
          </Stack>
          <Stack p={1} flex={1}>
              <Stack>
                  <Text level='title-sm'>{item?.orderStatus?.name}</Text>
                  {/* <Text level='body-sm'>12:13 PM 26/02/2024</Text> */}
              </Stack>
          </Stack>
          <Stack p={1} px={2} flex={1}>
              {/* <Stack flexDirection={"row"} flex={1}>
                  <Stack flex={1}>
                      <Text level='body-sm'>Alpino Classic Crunch Peanut Butter</Text>
                  </Stack>
                  <Text level='title-sm'>x2</Text>
              </Stack>
              <Stack flexDirection={"row"} flex={1}>
                  <Stack flex={1}>
                      <Text level='body-sm'>Alpino Classic Crunch Peanut Butter</Text>
                  </Stack>
                  <Text level='title-sm'>x2</Text>
              </Stack>
              <Stack flexDirection={"row"} flex={1}>
                  <Stack flex={1}>
                      <Text level='body-sm'>Alpino Classic Crunch Peanut Butter</Text>
                  </Stack>
                  <Text level='title-sm'>x2</Text>
              </Stack>
              <Stack flexDirection={"row"} flex={1}>
                  <Stack flex={1}>
                      <Text level='body-sm'>Alpino Classic Crunch Peanut Butter</Text>
                  </Stack>
                  <Text level='title-sm'>x2</Text>
              </Stack> */}
          </Stack>
          <Stack p={1} px={3} flex={1} alignItems={'end'}>
              <Stack>
                  <Text level='title-md'>₹{item?.amount?.toLocaleString('en-IN', {maximumFractionDigits:2})}</Text>
              </Stack>
          </Stack>
      </Stack>
    )
  }

export default ListView