

import toast from "react-hot-toast"
import Papa from "papaparse"
import { dispatch } from "../../store"
import { query } from "../../store/query"



export const exportOutletWithBrandAndQuantityToCsv = () => {
    const resolver = async() => {
        try {
            const data = []
            let pages = 1
            for(let i = 0; i < pages; i++){
                const response = await dispatch(query.endpoints.getStoreListWithTaggedBrand.initiate())
                // if(response.data?.data){
                //     res
                // }
                response.data?.data?.forEach(row => {
                    data.push({
                        "Outlet ID" : row.retailId ,
                        "Outlet Name" : row.retailStoreName ,
                        "Category Name" : row.parentProductCategoryName ,
                        "Subcategory Name" : row.productCategoryName ,
                        "Brand Name" : row.brandName ,
                        "Quantity" : row.quantityMapped ,
                        "Images" : row.uploadedImages?.join(";\n") ,
                        "Assigned To" : row.assignedUserName?.join(";") ,
                        "Activity Date" : row.activityDate && `${row.activityDate[2]}-${row.activityDate[1]}-${row.activityDate[0]}` ,
                    })
                })
                // response.data.pagination.total
            }
            const csvString = Papa.unparse(data, { delimiter: "," });
            console.log("csvString", csvString);
            const blob = new Blob([csvString], { type: "text/csv" })
            const file = window.URL.createObjectURL(blob)
            window.open(file, "_blank")
        } catch (error) {
            
        }
    }
    toast.promise(resolver(),{
        error : "Dowloading error : Outlet with brand & quantity",
        loading : "Dowloading in progress : Outlet with brand & quantity",
        success : "Dowloading completed : Outlet with brand & quantity"
    })
}