import { CloseRounded, Rotate90DegreesCcwRounded, Rotate90DegreesCwRounded, RotateLeftRounded, RotateRightRounded } from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/joy'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const Gallery = ({data, onClose}) => {
  const imageRef = useRef()
  const [screen, setScreen] = useState({
      width: window.screen.availWidth, 
      height: window.innerHeight
      // width: 500, 
      // height: 500
    })
    const [imageState, setImageState] = useState({
      scale : 0.9,
      rotate : 0,
    })
    const [selected, setSelected] = useState(data?.[0])

    const onkeypress = useCallback((event) => {
      onClose()
    }, [])

    const onRotateLeft = () => {
      setImageState(state => ({...state, rotate : state.rotate - 90}))
    }

    const onRotateRight = () => {
      setImageState(state => ({...state, rotate : state.rotate + 90}))
    }

    useEffect(() => {
      window.addEventListener("keypress", onkeypress, true)
      return () => window.removeEventListener("keypress", onkeypress, true)
    }, [])

  return (
    <Stack sx={{width: screen.width, height: screen.height}}>
      <div style={{position:"absolute", width: "100%", height: "100%"}}>
          <Stack sx={{width: "100%", height: "100%"}}>
              <Stack flex={1}>
                  <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                    <IconButton variant='soft' color='neutral' onClick={onRotateLeft} sx={{borderRadius: 360, mx: 0.5, my: 2}}>
                      <RotateLeftRounded />
                    </IconButton>
                    <IconButton variant='soft' color='neutral' onClick={onRotateRight} sx={{borderRadius: 360, margin: 2, marginLeft: 1}}>
                      <RotateRightRounded />
                    </IconButton>
                    <IconButton variant='solid' color='primary' onClick={onClose} sx={{borderRadius: 360, margin: 2}}>
                      <CloseRounded />
                    </IconButton>
                  </Stack>
                  <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                  </Stack>
                  <Stack>
                  </Stack>
              </Stack>
          </Stack>
        </div>
        <Stack flex={1}>
            <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                <img alt={selected} ref={imageRef} src={selected} style={{width: "100%", height: screen.height, objectFit: "contain", transform: `scale(${imageState.scale}) rotate(${imageState.rotate}deg)`, transformOrigin: "center", transition: "0.3s"}} />
            </Stack>
        </Stack>
    </Stack>
  )
}

export default Gallery