


import { Chip, Sheet, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'

const Detail = () => {
  return (
    <Stack>
        <Stack>
            <Text level="h4">Alpino Classic Crunch Peanut Butter 1 Kg</Text>
            <Stack flexDirection={"row"} pb={2}>
                <Text level='body-sm'>PD10000012</Text>
                <Stack pl={1}>
                    <Chip variant='solid' color='primary'>Active</Chip>
                </Stack>
            </Stack>
        </Stack>
        <Sheet sx={{p: 2, borderRadius: "md"}}>
            <Stack pb={3}>
                <Text level='title-md' pb={1.5}>SKU Details</Text>
                <Stack flexDirection={"row"} flexWrap={"wrap"} sx={{maxWidth: 784, rowGap: 1.2, columnGap: 6}}>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{minWidth: 360}}>
                        <Text level='body-md'>Brand</Text>
                        <Text level='title-md'>Alpino</Text>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{minWidth: 360}}>
                        <Text level='body-md'>Brand</Text>
                        <Text level='title-md'>Alpino</Text>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{minWidth: 360}}>
                        <Text level='body-md'>Brand</Text>
                        <Text level='title-md'>Alpino</Text>
                    </Stack>
                </Stack>
            </Stack>
            <Stack>
                <Text level='title-md' pb={1.5}>Pricing Details</Text>
                <Stack flexDirection={"row"} flexWrap={"wrap"} sx={{maxWidth: 784, rowGap: 1.2, columnGap: 6}}>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{minWidth: 360}}>
                        <Text level='body-md'>Brand</Text>
                        <Text level='title-md'>Alpino</Text>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{minWidth: 360}}>
                        <Text level='body-md'>Brand</Text>
                        <Text level='title-md'>Alpino</Text>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{minWidth: 360}}>
                        <Text level='body-md'>Brand</Text>
                        <Text level='title-md'>Alpino</Text>
                    </Stack>
                </Stack>
            </Stack>
        </Sheet>
    </Stack>
  )
}

export default Detail