


import { Button, Link, Stack } from '@mui/joy'
import React from 'react'
import Text from '../../components/Text'
import { ArrowBackRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material'
import {useNavigate} from "react-router-dom"

const Error404 = () => {
    const navigate = useNavigate()
    const onGoback = () => {
        navigate(-1)
    }
  return (
    <Stack flex={1} justifyContent={"center"} alignItems={"center"} sx={{px: 3, height: window.innerHeight}}>
        <Stack alignItems={"center"} sx={{pb: 24, transform: "scale(1.3)"}}>
            <Text level='h1' color='primary'>404</Text>
            <Text level='body-lg'>Page Not Found</Text>
            <Stack sx={{maxWidth: 144, mt: 1}}>
                <Button 
                    size='sm' 
                    variant='soft'
                    onClick={onGoback}
                    startDecorator={<ArrowBackRounded fontSize='small' />}>Go Back</Button>
            </Stack>
        </Stack>
    </Stack>
  )
}

export default Error404