

import { Stack } from '@mui/joy'
import React from 'react'
import NearbyPlacesList from '../../components/NearbyPlaces'
import Text from '../../components/Text'

const NearbyPlacePage = () => {
  return (
    <Stack p={3}>
        <Stack flexDirection={"row"} pb={2}>
            <Stack flex={1}>
                <Text level='h3'>Nearby Places</Text>
            </Stack>
            {/* <Button onClick={addNewBrand} startDecorator={<AddRounded fontSize='small' />}>Add Brand</Button> */}
        </Stack>
        <NearbyPlacesList />
    </Stack>
  )
}

export default NearbyPlacePage