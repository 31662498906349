


import React from 'react'
import { Avatar, Chip, List, Sheet, Stack, Table } from '@mui/joy'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Text from '../Text'
import moment from 'moment'
import { CheckRounded } from '@mui/icons-material'
import ActionModal from '../SKUActivityActionModal/ActionModal'
import ListItemView from './ListItemView'

const data = [
  {
    firstName: 'tanner',
    lastName: 'linsley',
    age: 24,
    visits: 100,
    status: 'In Relationship',
    progress: 50,
  },
  {
    firstName: 'tandy',
    lastName: 'miller',
    age: 40,
    visits: 40,
    status: 'Single',
    progress: 80,
  },
  {
    firstName: 'joe',
    lastName: 'dirte',
    age: 45,
    visits: 20,
    status: 'Complicated',
    progress: 10,
  }
]


const columnHelper = createColumnHelper()

const columns = [
  columnHelper.accessor('firstName', {
    header: () => "Last Updated",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row.lastName, {
    id: 'lastName',
    cell: info => info.getValue(),
    header: () => "SR Name",
    footer: info => info.column.id,
  }),
  columnHelper.accessor('age', {
    header: () => 'Store Name',
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    footer: info => info.column.id,
  }),
  columnHelper.accessor('progress', {
    header: 'Profile Progress',
    footer: info => info.column.id,
  }),
]


const UserList = (props) => {
  
  const table = useReactTable({
    data : props.data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Sheet variant='outlined' color='neutral' sx={{ borderRadius: 6, overflow: "hidden" }}>
      <Table variant='plain' borderAxis='xBetween' color='neutral' hoverRow size='sm' stickyHeader>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}  style={{backgroundColor: "red"}}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    :
                    <Text sx={{ padding: "12px 6px" }}>
                      {
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                      }
                    </Text>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {
            Array(20).fill(1)?.map((item, index) => <ListItemView item={item} index={index} />)
          }
        </tbody>
      </Table>
      <ActionModal />
    </Sheet>
  )
}

export default UserList