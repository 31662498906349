

import { Stack } from '@mui/joy'
import React from 'react'
import Text from '../../components/Text'
import { ProductList } from '../../components/Product'

const ProductPage = () => {
  return (
    <Stack p={3}>
      <Stack flexDirection={"row"} pb={2}>
          <Stack flex={1}>
              <Text level='h3'>Products</Text>
          </Stack>
          {/* <Button onClick={addNewBrand} startDecorator={<AddRounded fontSize='small' />}>Add Brand</Button> */}
      </Stack>
      <ProductList />
    </Stack>
  )
}

export default ProductPage