


import { Autocomplete, AutocompleteOption, Avatar, Box, Button, Chip, CircularProgress, Grid, IconButton, Input, Modal, Sheet, Stack, Step, StepIndicator, Stepper } from '@mui/joy'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Text from '../Text'
import ZoomImage from '../ZoomImage'
import { useGetBrandQuery, useGetStoreSkuActivityQuery } from '../../store/query'
import { AddRounded, CheckCircleRounded, RotateLeftRounded, RotateRightRounded, ZoomInRounded, ZoomOutRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import useSkuImageActivityForm from '../../hooks/useSkuImageActivityForm'
import { Controller } from 'react-hook-form'
import NoActivityView from './NoActivityView'
import LoadingView from './LoadingView'
import ImageTagManager from './ImageTagManager'


const viewProps = {
    containerStyle: {},
    zoomPosition: "",
    zoomOffset : {}
}


const ActivityActionView = (props = viewProps) => {
    const ref = useRef()
    const app = useAppContext()
    const activity = useSkuImageActivityForm()
    const focused = useRef(true)

    const addNewBrand = async() => {
        focused.current = false
        await app.addBrandModalRef.current?.open({
            id : "new"
        })
        focused.current = true
    }

    if(activity.isLoading) return <LoadingView containerStyle={props.containerStyle} />
    if(!activity.hasActivity) return <NoActivityView containerStyle={props.containerStyle} />

    return (
        <Stack
            ref={ref}
            // variant="outlined"
            sx={props.containerStyle}
        >
            {
                activity.images?.fields?.map((item, index) => {
                    if(index != activity.index) return null
                    return(
                        <Controller
                            control={activity.control}
                            name={`mappedBrands.${index}.brand_quant_info`}
                            render={({field : {value, onChange}}) => <ImageLabelWindow item={item} focused={focused} selected={value} index={index} onChange={onChange} activity={activity} />} 
                            />
                    )
                })
            }
            <Stack sx={{mt: 1, height: 2, width: "100%", backgroundColor: "#C2CBE3", alignSelf: "center", opacity: 0.4}} />
            <Stack flexDirection={"row"} pt={1.5}>
                <Stack flexDirection={"row"} flex={1}>
                    <Button variant='plain' onClick={addNewBrand}>Add Brands</Button>
                    <Button variant='plain'>Report Issue</Button>
                </Stack>
                <Button onClick={activity.submit}>Submit</Button>
            </Stack>
        </Stack>
    )
}

const ImageLabelWindow = (props) => {
    const {activity} = props
    const searchRef = useRef()

    const brand = activity.brand

    const [search, setSearch] = useState([])
    const selected = props.selected
    const selectedOption = useMemo(() => {
        return selected.map(item => {
            return brand.data?.find(brand => brand.id == item) ?? item
        })
    }, [selected, brand.data])
    const focused = props.focused
    const onkeydown = useCallback((event) => {
        if(focused.current){
            searchRef.current?.firstChild?.firstChild?.focus()
        }
    }, [])

    const onSelection = useCallback((item, quantity) => {
        if(selected.includes(item.id)){
            props.onChange(selected.filter(id => id != item.id) ?? [])
        }else{
            props.onChange([...selected, item.id])
        }
    }, [selected])

    const onQuantityChange = useCallback((item, quantity) => {
        console.log("errocrash", item, quantity, selected);
        if(selected.find(brand => brand.id == item.id)){
            if(quantity <= 0){
                return props.onChange(selected.filter(brand => brand.id != item.id) ?? [])
            }
            props.onChange(selected.map(brand => {
                if(brand.id == item.id){
                    return {
                        ...brand,
                        quantity: quantity
                    }
                }
                return brand
            }))
        }else{
            if(quantity <= 0) return
            props.onChange([...selected, {
                ...item,
                quantity : quantity
            }])
        }
    }, [selected])

    // useEffect(() => {
    //     window.addEventListener("keydown", onkeydown, true)
    //     return () => window.removeEventListener("keydown", onkeydown, true)
    // }, [])

    return(
        <Stack flexDirection={"row"} flex={1}>
            <Stack flex={1} sx={{ maxWidth: "420px" }}>
                <Stack flexDirection={"row"} alignItems={"center"} height={60}>
                    {/* <Chip>10/40</Chip> */}
                    <Stack flexDirection={"row"} alignItems={"center"}>
                        <Text level='title-md' pr={1}>Category</Text>
                        <Chip color='warning'>{activity.data?.productCategoryName}</Chip>
                    </Stack>
                </Stack>
                <Sheet variant='soft' sx={{display: "flex", flex: 1, borderRadius: "sm"}}>
                    {/* <ZoomImage position={props.zoomPosition} offset={props.zoomOffset} key={"sku-action"} src={props.item.image_url} style={{ width: "420", height: "100%", objectFit: "scale-down" }} /> */}
                    <ImageTagManager src={props.item.image_url} style={{ width: "420px", aspectRatio: 1, objectFit: "contain" }} />
                </Sheet>
                <Stack flexDirection={"row"}  pt={2}>
                    {
                        props.activity.images?.fields?.map((item, index) => {
                            return(
                                <Sheet variant='outlined' sx={{width: 48, height: 48, mr: 1.5, borderWidth: 2, borderColor: props.activity.index == index ? "green" : "lightgray"}} onClick={() => props.activity.setIndex(index)}>
                                    <img src={item.image_url} style={{width: "100%", height: "100%", objectFit: "contain"}} />
                                </Sheet>
                            )
                        })
                    }
                </Stack>
            </Stack>
            <Stack sx={{mx: 2, width: 2, height: "96%", backgroundColor: "#C2CBE3", alignSelf: "center", opacity: 0.4}} />
            <Stack flex={1} pl={2} >
                <Stack flexDirection={"row"} alignItems={"center"} height={40}>
                    <Stack flex={1}>
                        <Text level='title-md'>Brands</Text>
                    </Stack>
                    {/* <Button size='sm' variant='soft'>Skip</Button> */}
                </Stack>
                <Box pb={2}>
                    <Autocomplete 
                        multiple
                        ref={searchRef} 
                        value={selectedOption} 
                        onChange={(e, item) => props.onChange(item.map(item => item.id))}
                        variant='outlined' 
                        size='sm' 
                        placeholder='Select brand'
                        options={brand?.data ?? []} 
                        getOptionLabel={(option) => option.name}
                        renderTags={() => null}
                        renderOption={OptionListItem}
                        sx={{ maxWidth: 400 }} />
                </Box>
                <Stack flex={1}>
                    {/* <Text level='title-md'>Selected</Text>
                    <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"} justifyContent={"flex-start"} pt={1} pb={2} sx={{minHeight: "80px"}}>
                        {
                            brand.data?.map(item => <SelectedListItem item={item} selected={selected} />)
                        }
                    </Stack> */}
                    <Text level='title-md'>Popular</Text>
                    <Stack>
                        <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"} py={1} style={{ flex: 1, overflow: "scroll" }}>
                            {
                                brand.data?.map((item, index) => <ListItem item={item} index={index} selected={selected} onSelect={onQuantityChange} />)
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}


const OptionListItem = (props, option) => {
    // console.log("olits", props);
    return(
        <AutocompleteOption {...props}>
            <Text>{option.name}</Text>
        </AutocompleteOption>
    )
}

const SelectedListItem = (props) => {
    const {item, selected} = props
    const isSelected = useMemo(() => selected.includes(item.id), [selected])
    if(isSelected){
        return (
            <Stack sx={{width: 124}}>
                <Box sx={{ width: "100%", borderRadius: 4 }}>
                    <img src={item.logo} alt="logo" style={{ width: "100%", aspectRatio: 1 }} />
                    <Text level='body-xs' textAlign={"center"}>{item.name}</Text>
                </Box>
                <Stack>
                    <Input
                        size='sm'
                        startDecorator={<IconButton size='sm'><AddRounded /></IconButton>}
                        endDecorator={<IconButton size='sm'><AddRounded /></IconButton>}
                     />
                </Stack>
            </Stack>
        )
    }
    return null
}

const ListItem = (props) => {
    const inputRef = useRef()
    const {item, onSelect, selected} = props
    const [quantity, setQuantity] = useState(0)
    const isSelected = useMemo(() => selected.includes(item?.id), [selected])
    const onQuantityChange = (event) => {
        const quantity = event.target.value
        onSelect(item, quantity)
    }
    const onSubtract = () => {
        const val = Math.max(0, quantity - 1)
        onSelect(item, val)
        setQuantity(val)
    }
    const onAdd = () => {
        onSelect(item, quantity + 1)
        setQuantity(quantity + 1)
    }
    return(
        <Stack flex={1} sx={{ position:"relative", width: 96, borderRadius: 4 }}>
            <div style={{position: "absolute", height: "100%", top: 0, right: 0}}>
                {
                    isSelected && <CheckCircleRounded color='success' />
                } 
            </div>
            <Stack>
                <img src={item.logo} alt="logo" style={{ width: "100%", aspectRatio: 1, border: "1px solid rgba(0,0,0,0.1)", borderRadius: 4, overflow: "scroll" }} />
                <Text level='title-sm' textAlign={"center"} py={"8px"}>{item.name}</Text>
            </Stack>
            <Stack flex={1} justifyContent={"flex-end"} >
                <Input
                    ref={inputRef}
                    startDecorator={<Text variant='solid' color='primary' onClick={onSubtract} sx={{px: 1, cursor: "pointer"}} level='title-md'>-</Text>}
                    endDecorator={<Text variant='solid' color='primary' onClick={onAdd} sx={{px: 1, cursor: "pointer"}} level='title-md'>+</Text>}
                    minValue={0}
                    maxLength={3}
                    value={quantity}
                    onChange={(e) => {
                        setQuantity(e.target.value)
                        onQuantityChange(e)
                    }}
                    style={{textAlign: "center"}}
                    slotProps={{
                        input : {
                            style : {
                                textAlign: "center",
                            }
                        }
                    }}
                    sx={{
                        "--Input-gap" : 0.2,
                        "--Input-startDecorator" : {},
                        px: 0.5,
                        width: "100%",
                        overflow: "hidden",
                        transform: "scale(0.9)"
                    }}
                    />
            </Stack>
        </Stack>
    )
    // return (
    //     <Box sx={{ width: 84, borderRadius: 4 }}>
    //         <img src={item.logo} alt="logo" style={{ width: "100%", aspectRatio: 1 }} />
    //         <Text level='body-xs' textAlign={"center"}>{item.name}</Text>
    //     </Box>
    // )
}


const ListItem2 = (props) => {
    const {item, onSelect, selected} = props
    const isSelected = useMemo(() => selected.includes(item.id), [selected])
    if(isSelected){
        return(
            <Box sx={{ position:"relative", width: 56, borderRadius: 4 }} onClick={() => onSelect(item)}>
                <div style={{position: "absolute", height: "100%", top: 0, right: 0}}>
                    <CheckCircleRounded color='success' />
                </div>
                <img src={item.logo} alt="logo" style={{ width: "100%", aspectRatio: 1 }} />
                <Text level='body-xs' textAlign={"center"}>{item.name}</Text>
            </Box>
        )
    }
    return (
        <Box sx={{ width: 56, borderRadius: 4 }} onClick={() => onSelect(item)}>
            <img src={item.logo} alt="logo" style={{ width: "100%", aspectRatio: 1 }} />
            <Text level='body-xs' textAlign={"center"}>{item.name}</Text>
        </Box>
    )
}

export default ActivityActionView