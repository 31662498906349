// import { sleep } from "../utils";

export default () => {

    let taskList = []
    let taskRunningStatus = false
    
    const actionType = {
        userListBulkUpload : "userListBulkUpload"
    }
    
    self.addEventListener("message", event => { /* eslint-disable-line no-restricted-globals */
        console.log("new Messages", event.data);
        switch(event.data.type){
            case actionType.userListBulkUpload : {
                createTask({id : 1234, taskName : "task"})
                break;
            }
            default : {}
        }
        runTask()
    })
    
    const createTask = (task) => {
        taskList.push(task)
        self.postMessage()   /* eslint-disable-line no-restricted-globals */
    }
    
    const removeTask = (taskId) => {
        taskList = taskList.filter(item => !(item.id == taskId))
    }
    
    const markTaskCompleted = () => {
        self.postMessage()   /* eslint-disable-line no-restricted-globals */
    }
    
    
    const runTask = async() => {
        try {
            if(taskRunningStatus) return
            while(taskList.length > 0){
                const task = taskList[0]
                taskRunningStatus = true
                console.log("Task Running", task);
                await sleep(3000)
                runTask()
                // removeTask(task.id)
            }
        } catch (error) {
            console.log("runTask", error);
        }finally {
            if(taskList.length <= 0){
                taskRunningStatus = false
            }
        }
    }
    
    
    const sleep = (time = 1000) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, time)
        })
    }
}