


import { Avatar, Box, Chip, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'
import { useGetEmployeeInfoQuery } from '../../store/query'

const BasicDetail = () => {
  const user = useGetEmployeeInfoQuery("9340" + Math.random() * 10)
  return (
    <Box>
        <Stack flexDirection={"row"} sx={{backgroundColor: "rgba(0,0,0,0.02)", borderRadius: "lg", padding: 2}}>
            <Avatar alt='Alok' variant='soft' color='primary' sx={{width: 120, height: 120, fontSize: 48}} />
            <Box px={2}>
                <Text level='h3'>Alok Prakash</Text>
                <Chip variant='solid' color='primary'>Sales Representatives</Chip>
                <Stack flexDirection={"row"} flex={1} mt={2}>
                  <Text>
                    <Text level="body-sm">Email   :</Text>
                    <Text level="title-md">alokprakash@fade.mkt</Text>
                  </Text>
                  <Text>Phone</Text>
                  <Text>+919340655701</Text>
                </Stack>
            </Box>
            <Box>
              <Chip>Active</Chip>
            </Box>
        </Stack>
        <Stack flexDirection={"row"} sx={{backgroundColor: "rgba(0,0,0,0.02)", borderRadius: "lg", padding: 2}}>
            <Box px={2} sx={{minWidth: "200px"}}>
                <Text level='title-md'>Current Address</Text>
                <Box>
                  <Text level='body-sm'>Staywell Accomodation</Text>
                  <Text level='body-sm'>Sector 68, Badshahpur</Text>
                  <Text level='body-sm'>Gurgaon, Haryana, India</Text>
                </Box>
            </Box>
            <Box px={2} sx={{minWidth: "200px"}}>
                <Text level='title-md'>Current Address</Text>
                <Box>
                  <Text level='body-sm'>Staywell Accomodation</Text>
                  <Text level='body-sm'>Sector 68, Badshahpur</Text>
                  <Text level='body-sm'>Gurgaon, Haryana, India</Text>
                </Box>
            </Box>
            <Box px={2} sx={{minWidth: "200px"}}>
                <Text level='title-md'>Current Address</Text>
                <Box>
                  <Text level='body-sm'>Staywell Accomodation</Text>
                  <Text level='body-sm'>Sector 68, Badshahpur</Text>
                  <Text level='body-sm'>Gurgaon, Haryana, India</Text>
                </Box>
            </Box>
        </Stack>
    </Box>
  )
}

export default BasicDetail