


import { List, ListItemButton, Sheet, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'

const Detail = () => {
  return (
    <Stack>
        <Stack>
            <Text level='h3'>Sona Beat</Text>
        </Stack>
        <Stack>
            <Stack sx={{width: 144}}>
                <Sheet variant='outlined'>
                    <List variant='soft' size='md'>
                        <ListItemButton>
                            Users
                        </ListItemButton>
                        <ListItemButton>
                            Stores
                        </ListItemButton>
                    </List>
                </Sheet>
            </Stack>
        </Stack>
    </Stack>
  )
}

export default Detail