


import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AuthRouter from './AuthRouter'
import { useAuthentication } from '../hooks/useLogin'
import MainRouter from './MainRouter'
import { Box, Stack } from '@mui/joy'
import NavigationBar from '../components/NavigationBar'
import ContextMenu from '../components/ContextMenu'
import { useAppContext } from '../hooks/useAppContext'
import EditUserRoleModal from '../components/EditUserRoleModal'
import EditUserProfileModal from '../components/EditUserProfile'
import { AddUserModal } from '../components/AddUser'
import GalleryModalView from '../components/GalleryView'
import { AddBrandModal } from '../components/AddBrand'
import Error404 from '../pages/error404'
import { StagingAlert } from '../components/AlertBanner'
import SearchBar, { SearchModal } from '../components/SearchBar'

const Router = () => {
    const auth = useAuthentication()
    const app = useAppContext()

    return (
        <BrowserRouter >
            {
                auth.isLogin ?
                    (
                        <Stack sx={{ flex: 1, width: "100vw", height: window.innerHeight }}>
                            <Stack direction={"row"} flex={1}>
                                <NavigationBar />
                                <Stack flex={1} sx={{overflow: "scroll", height: window.innerHeight}}>
                                    <StagingAlert />
                                    <SearchBar />
                                    <MainRouter />
                                </Stack>
                            </Stack>
                            <ContextMenu ref={app.contextMenuRef} />
                            <EditUserRoleModal refs={app.editUserRoleModalRef} />
                            <EditUserProfileModal ref={app.editUserProfileModalRef} />
                            <AddUserModal ref={app.addUserModalRef} />
                            <GalleryModalView  ref={app.galleryRef}/>
                            <AddBrandModal refs={app.addBrandModalRef} />
                            <SearchModal refs={app.searchModalRef} />
                        </Stack>
                    ) :
                    <AuthRouter />
            }
        </BrowserRouter>
    )
}

export default Router