

import { Box, Button, Divider, FormControl, FormLabel, Input, Option, Select, Sheet, Stack } from '@mui/joy'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Text from '../Text'
import { useAddUserMutation, useGetUserRoleQuery } from '../../store/query'
import toast from 'react-hot-toast'

const AddUserView = (props, ref) => {

    const userRole = useGetUserRoleQuery()
    const [addUser, {isLoading, isSuccess, error}] = useAddUserMutation()
    const form = useForm({
        defaultValues: {
            countryCode : "+91",
            password : "vPifb@K1",
            email: ""
        }
    })

    const onSubmit = async(data) => {
        console.log("data", isSuccess, error);
        await addUser(data)
    }

    useEffect(() => {
        if(isSuccess){
            if(props.onSuccess) props.onSuccess()
            toast("User Added")
        }
    }, [isSuccess])

    return (
        <Sheet sx={{ width: "500px", padding: 2, borderRadius: 6 }}>
            <Box>
                <Text level="h4" pb={2}>Add User</Text>
                <Stack flexDirection={"row"} gap={1.5} pb={1}>
                    <Stack flex={1}>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Controller
                                control={form.control}
                                name='firstName'
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input value={value} onChange={e => onChange(e.target.value)} />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack flex={1}>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Controller
                                control={form.control}
                                name='lastName'
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input value={value} onChange={e => onChange(e.target.value)} />
                                )}
                            />
                        </FormControl>
                    </Stack>
                </Stack>
                <Stack flexDirection={"row"} gap={1.5} pb={1}>
                    <Stack flex={1}>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Controller
                                control={form.control}
                                name='email'
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input value={value} onChange={e => onChange(e.target.value)} />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack flex={1}>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Controller
                                control={form.control}
                                name='phoneNumber'
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input value={value} onChange={e => onChange(e.target.value)} />
                                )}
                            />
                        </FormControl>
                    </Stack>
                </Stack>
                {/* <Box pb={1}>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Controller
                            control={form.control}
                            name='email'
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Input value={value} onChange={e => onChange(e.target.value)} />
                            )}
                        />
                    </FormControl>
                </Box> */}
                <Box pb={2.5}>
                    {/* <FormControl>
                        <FormLabel>Role</FormLabel>
                        <Controller
                            control={form.control}
                            name='role'
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Select defaultValue={value} onChange={e => onChange(e.target.value)}>
                                    {
                                        userRole.data?.map(item => <Option value={item}>{item}</Option>)
                                    }
                                </Select>
                            )}
                        />
                    </FormControl> */}
                </Box>
                <Box sx={{textAlign: "center"}}>
                    <Button
                        loading={isLoading}
                        onClick={form.handleSubmit(onSubmit)}>Submit</Button>
                </Box>
            </Box>
        </Sheet>
    )
}

export default AddUserView