

import { CircularProgress, LinearProgress, Stack } from '@mui/joy'
import React, { useEffect, useState } from 'react'
import Text from '../../components/Text'
import axios from 'axios'

const ServerErrorPage = () => {
    const [error, setError] = useState()
    const [timer] = useState(0)
    const checkServerStatus = () => {
        try {

        } catch (error) {
            
        }
    }
    // useEffect(() => {
    //     checkServerStatus()
    // }, [timer])
  return (
    <Stack flex={1} justifyContent={"center"} alignItems={"center"} sx={{width: "100%", height: window.innerHeight}}>
        <Stack textAlign={"center"}>
            <LinearProgress size='sm' color='primary' sx={{mb: 6}} />
            <Text level='h1' color='primary'>ERROR 502</Text>
            <Text level='body-lg' mt={2}>Server Maintenance Is In Progress</Text>
            <Text level='body-xs'>You will be redirected automatically to your work once maintenance is done.</Text>
            <Text level='body-xs'>Average deployment time is 2 minute</Text>
        </Stack>
    </Stack>
  )
}

export default ServerErrorPage