


import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RouteName } from './routes'
import DashboardPage from '../pages/dashboard'
import EmployeeDetailPage from '../pages/employee-detail'
import RetailStorePage from '../pages/retail-store'
import ManageUserRolePage from '../pages/manage-user-role'
import BulkUploadPage from '../pages/bulk-upload'
import UserSkuActivityPage from '../pages/user-sku-activity'
import StoreDetailPage from '../pages/store-detail'
import ProfilePage from '../pages/profile'
import StoreSkuActivityPage from '../pages/store-sku-activity'
import StoreSkuActivityActionPage from '../pages/storeSkuActivityAction'
import BrandPage from '../pages/brands'
import Error404 from '../pages/error404'
import SkuCategoryList from '../components/SkuCategory'
import SkuCategoryPage from '../pages/skuCategory'
import NearbyPlacePage from '../pages/nearbyPlaces'
import BeatListPage from '../pages/beatList'
import DistributorPage from '../pages/distributor'
import WarehousePage from '../pages/warehouse'
import OrderPage from '../pages/orders'
import ProductPage from '../pages/product'
import OrderDetailPage from '../pages/orderDetail'
import BeatDetailPage from '../pages/beatDetail'
import ProductDetailPage from '../pages/ProductDetail'
import BizomToFairconnectCsvConverterPage from '../pages/bulk-upload/BizomToFairconnectCsvConverterPage'

const MainRouter = () => {
  return (
    <Routes>
        <Route path={"/employee/:employeeId"} element={<EmployeeDetailPage />} />
        <Route path={"/stores"} element={<RetailStorePage />} />
        <Route path={"/store/:storeId"} element={<StoreDetailPage />} />
        <Route path={"/manage-user-role"} element={<ManageUserRolePage />} />
        <Route path={"/bulk-upload"} element={<BulkUploadPage />} />
        <Route path={"/bulk-upload/bizom"} element={<BizomToFairconnectCsvConverterPage />} />
        <Route path={"/sales-representatives"} element={<UserSkuActivityPage />} />
        <Route path={"/profile"} element={<ProfilePage />} />
        <Route path={"/store-sku-activity-action"} element={<StoreSkuActivityActionPage />} />
        <Route path={"/store-sku-activity"} element={<StoreSkuActivityPage />} />
        <Route path={"/brands"} element={<BrandPage />} />
        <Route path={"/retail-beat"} element={<BeatListPage />} />
        <Route path={"/beat/:beatId"} element={<BeatDetailPage />} />
        <Route path={"/sku-category"} element={<SkuCategoryPage />} />
        <Route path={"/distributors"} element={<DistributorPage />} />
        <Route path={"/warehouses"} element={<WarehousePage />} />
        <Route path={"/orders"} element={<OrderPage />} />
        <Route path={"/order/:orderId"} element={<OrderDetailPage />} />
        <Route path={"/products"} element={<ProductPage />} />
        <Route path={"/product/:productId"} element={<ProductDetailPage />} />
        <Route path={"/nearby-places"} element={<NearbyPlacePage />} />
        <Route path={"/"} element={<DashboardPage />} />
        <Route path={"*"} element={<Error404 />} />
    </Routes>
  )
}

export default MainRouter