


import { Stack } from '@mui/joy'
import React, { useEffect, useState } from 'react'
import Text from '../Text'
import { useSelector } from 'react-redux'
import { updateTableFilter } from '../../store/actions/tableAction'
import PaginatedFooter from './PaginatedFooter'
import EmptyComponent from './EmptyComponent'

const defaultFilter = {
    currentPage : 0,
    totalPage : 1,
    itemPerPage : 10,
    search : "",

}

const CustomTable = (props = {
    data: [],
    query : () => {},
    getDataFromQuery : () => {},
    getEncodedFilter : () => {},
    getTotalPages : () => {},
    name : "",
    standalone : false,
    filter : {},
    onFilter : () => {},
    height: 0,
    renderItem: React.Component,
    HeaderComponent: React.Component,
    FooterComponent: React.Component
}) => {
    const [filter, setFilter] = useState({
        ...defaultFilter,
        ...props.filter
    })

    const query = props.query && props.query(props.getEncodedFilter ? props.getEncodedFilter(filter) : "")
    const data = props.query ? props.getDataFromQuery(query.data) : props.data

    const handleSearchInput = (e) => {
        setFilter({
            search : e.target.value,
            currentPage : 0
        })
    }

    const updateFilter = (newFilter) => {
        // updateTableFilter(props.name, filter)
        setFilter(filter => {
            return {
                ...filter,
                ...newFilter
            }
        })
    }

    useEffect(() => {

    }, [filter])

    useEffect(() => {
        setFilter(filter => {
            return {
                ...filter,
                totalPage : props.getTotalPages(query.data)
            }
        })
    }, [query.data])

    return (
        <Stack flex={1}>
            {/* <Text>{JSON.stringify(filter)}</Text> */}
            {
                props.HeaderComponent && <props.HeaderComponent filter={filter} handleSearchInput={handleSearchInput} handleFilterChange={updateFilter}   />
            }
            <Stack sx={{height: props.height ?? 480, backgroundColor: "#fff", overflow: "scroll"}}>
                {
                    data?.map(props.renderItem)
                }
                {
                    data?.length == 0 && <EmptyComponent loading={query.isLoading} />
                }
            </Stack>
            {
                props.FooterComponent && <props.FooterComponent filter={filter} handleFilterChange={updateFilter} />
            }
            <PaginatedFooter filter={filter} handleFilterChange={updateFilter} />
        </Stack>
    )
}

export default CustomTable