


import { Box, Stack } from '@mui/joy'
import { ImageList, ImageListItem } from '@mui/material'
import React, { useMemo } from 'react'
import { useGetBrandQuery } from '../../store/query'
import Text from '../Text'

const StoreLoyaltyProgramme = ({data}) => {
    const brand = useGetBrandQuery()
    const list = useMemo(() => {
        if(data.length > 0){
            return brand.data?.filter(item => {
                return data.includes(item.id)
            }) ?? []
        }
        return []
    }, [brand.data])
    return (
        <Box>
            {
                list.length == 0 && (
                    <Box>
                        <Text>No active programmes</Text>
                    </Box>
                )
            }
            <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} sx={{ width: "100%", height: 450 }} cols={12} rowHeight={64}>
                {list.map((item) => (
                    <Stack sx={{width: 80}}>
                        <Stack sx={{width: "100%", aspectRatio: 1}}>
                            <img src={item.logo} alt={item.name} style={{objectFit:"contain", width: "100%", height: "100%"}} />
                        </Stack>
                        <Text level='body-sm' sx={{textAlign: "center", wordWrap: "break-word"}}>{item?.name}</Text>
                    </Stack>
                ))}
            </Stack>
        </Box>
    )
}

export default StoreLoyaltyProgramme