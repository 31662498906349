


import { Avatar, Box, Chip, IconButton, Stack } from '@mui/joy'
import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import Text from '../../components/Text'
import { Height, MoreHorizRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { useGetUserListQuery } from '../../store/query'
import Table from '../../components/Table'
import { Rating } from '@mui/material'
import { Link } from 'react-router-dom'


const columnHelper = createColumnHelper()

const columns = [
    columnHelper.accessor("id", {
        header : "Sr",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>{props.row.index + 1}</Text>
            )
        },
        enableResizing : true,
        size: "20px"
    }),
    columnHelper.accessor("displayName", {
        header : "Name",
        cell : props => {
            console.log("pro", props);
            return(
                <Stack direction={"row"} alignItems={"center"}>
                    <Avatar src={props.row.original?.profilePicS3Url} alt={props.getValue()} size='sm' variant='soft' color='neutral' />
                    <div>
                        <Link to={`/employee/${props.row.original?.id}`}>
                            <Text level='body-xs' sx={{ padding: "0 12px 0 12px" }}>{props.getValue()}</Text>
                        </Link>
                        {/* <Text level='body-xs' sx={{ padding: "0 12px 0 12px" }}>{props.row.original?.email}</Text> */}
                    </div>
                </Stack>
            )
        },
        minSize: "240px"
    }),
    columnHelper.accessor("totalOutletVisited", {
        header : "Outlet Visited",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>{parseInt(Math.random() * 100)}</Text>
            )
        },
        enableResizing : true,
        size: "20px"
    }),
    columnHelper.accessor("lastOutletVisited", {
        header : "Last Outlet Visited",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>D.K. Pharmacy</Text>
            )
        },
        enableResizing : true,
        size: "20px"
    }),
    columnHelper.accessor("averageDailyPictures", {
        header : "Average Daily Pictures",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>{parseInt(Math.random() * 100)}</Text>
            )
        },
        enableResizing : true,
        size: "20px"
    }),
    columnHelper.accessor("weeklyStreak", {
        header : "Weekly Streak",
        cell : props => {
            return(
                <Rating size='small' value={3} />
            )
        },
    }),
    columnHelper.accessor("dataPoinsTagged", {
        header : "Data Points Tagged",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>{parseInt(Math.random() * 100)}</Text>
            )
        },
        enableResizing : true,
        size: "20px"
    }),
    // columnHelper.display({
    //     id: "actions",
    //     cell : props => {
    //         return <MoreButton />
    //     }
    // })
]

const MoreButton = () => {
    const app = useAppContext()
    const onContextMenu = (event) => {
        event.preventDefault()
        app.contextMenuRef?.current?.open({
            data : null,
            top: event.clientY - 6,
            left : event.clientX + 2,
        })
    }
    return(
        <IconButton size="sm" onClick={onContextMenu}>
            <MoreHorizRounded fontSize="small" />
        </IconButton>
    )
}

const UserSkuActivityPage = () => {
    const userList = useGetUserListQuery()
    console.log("userlist", userList.data);
  return (
    <Box px={3} py={2}>
        <Text level='h2' mb={2}>Sales Representatives</Text>
        <Table
            data={userList.data ?? []}
            loading={userList.isLoading}
            error={userList.isError}
            columns={columns} />
    </Box>
  )
}

export default UserSkuActivityPage