

import React from 'react'
import Table from '../Table'
import Text from '../Text'
import { Button, Checkbox, Chip, IconButton, Input, Select, Stack } from '@mui/joy'
import { createColumnHelper } from '@tanstack/react-table'
import { CloseRounded, MoreHorizRounded, SearchRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { renderMenu } from '../ContextMenu'
import CustomTable from '../Table/CustomTable'
import { SelectDateRange, SelectStore } from '../Selection'
import Link from '../Link'
import { parseTimeArray } from '../../utils'
import { useGetBeatListQuery } from '../../store/query'

const columnHelper = createColumnHelper()

const BeatListView = () => {

    const app = useAppContext()

    const onRowClicked = (event, row) => {
        const contextMenuData = [
            {
                id: 1,
                title: "Edit beat",
                onClick: () => app.editUserRoleModalRef?.current?.open(row)
            },
            {
                id: 1,
                title: "Add/Remove Store"
            },
            {
                id: 1,
                title: "Add/Remove Store"
            },
            {
                id: 1,
                title: "Deactivate"
            }
        ]
        app.contextMenuRef?.current?.open({
            data: renderMenu(contextMenuData),
            top: event.clientY + 1,
            left: event.clientX + 1,
        })
    }
    
    const getDataFromQuery = response => response?.data 

    const getEncodedFilter = filter => {
        let string = "", expression = ""
        if(filter.currentPage) string = `&page=${filter.currentPage}`
        if(filter.search) expression += `name==${filter.search};`
        return string + (expression ? "&filterExpression="+expression : "")
    }

    const getTotalPages = response => {
        return response?.pagination.totalPages
    }

    return (
        <CustomTable
            name='MasterOrderTable'
            query={useGetBeatListQuery}
            getDataFromQuery={getDataFromQuery}
            filter={{param: 12345}}
            getEncodedFilter={getEncodedFilter}
            height={560}
            getTotalPages={getTotalPages}
            HeaderComponent={ListHeaderComponent}
            renderItem={ListItemView}
        />
    )
}



export const ListHeaderComponent = (props) => {
    console.log("props", props.filter);
    return (
        <Stack>
            {/* <Stack pb={3}>
                <Text level='title-md' pb={1}>Filter</Text>
                <Stack flexDirection={"row"} gap={2}>
                    <SelectDateRange />
                    <SelectStore />
                    <Select placeholder="User" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                </Stack>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    {
                        Array(20).fill(1).map(() => <Chip variant='outlined' color='neutral' sx={{color: "gray"}} endDecorator={<CloseRounded fontSize='small' sx={{transform: "scale(0.85)"}} />}>Hello</Chip>)
                    }
                </Stack>
            </Stack> */}
            <Stack pb={2}>
                <Text level='title-md' pb={0.4}>All List</Text>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <Input
                        startDecorator={<SearchRounded />}
                        onChange={props.handleSearchInput} 
                        sx={{ flex: 1, maxWidth: 1000 }}
                    />
                    <Stack flex={1} />
                    <Button>Download</Button>
                </Stack>
            </Stack>
            <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} borderTop={"1px solid #ededed"} sx={{ backgroundColor: "#F7F8FA" }}>
                <Stack p={1} px={2} sx={{ width: 20 }}>
                    <Stack>
                        <Checkbox size='sm' />
                    </Stack>
                </Stack>
                <Stack p={1} sx={{ width: 124 }}>
                    <Stack>
                        <Text level='title-sm'>Beat ID</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{ maxWidth: 244, flex: 1 }}>
                    <Stack>
                        <Text level='title-sm'>Beat Name</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{ width: 124 }}>
                    <Stack>
                        <Text level='title-sm'>Users</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{ width: 84 }}>
                    <Stack>
                        <Text level='title-sm'>Status</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{ width: 144 }}>
                    <Stack>
                        <Text level='title-sm'>Total Outlets</Text>
                    </Stack>
                </Stack>
                {/* <Stack p={1} px={2} flex={1}>
                    <Stack flex={1}>
                        <Text level='title-sm'>Product</Text>
                    </Stack>
                </Stack>
                <Stack p={1} px={3} flex={1} alignItems={'end'}>
                    <Stack>
                        <Text level='title-sm'>Amount</Text>
                    </Stack>
                </Stack> */}
            </Stack>
        </Stack>
    )
}


export const ListItemView = (item, index) => {
    return (
        <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} backgroundColor="#fff">
            <Stack p={2} sx={{ width: 20 }}>
                <Stack>
                    <Checkbox size='sm' />
                </Stack>
            </Stack>
            <Stack p={1} sx={{ width: 124 }}>
                <Stack>
                    {/* <Link to={`/order/${item?.id}`}> */}
                        <Text level='title-sm' pl={0.4}>{item?.erpId ?? "NO ID"}</Text>
                    {/* </Link> */}
                    {/* <Text level='body-sm'>{parseTimeArray(item.createdAt, null, true).format("DD MMM YYYY")}</Text> */}
                </Stack>
            </Stack>
            <Stack p={1} sx={{ flex: 1, maxWidth: 244 }}>
                <Stack>
                    {/* <Link to={`/beat/${item?.outletId}`}> */}
                        <Text level='title-sm'>{item?.name}</Text>
                    {/* </Link> */}
                </Stack>
            </Stack>
                <Stack p={1} sx={{ width: 124 }}>
                    <Stack>
                        <Text level='title-sm'>{item?.users[0]?.displayName}</Text>
                    </Stack>
                </Stack>
            <Stack p={1} sx={{ width: 84 }}>
                <Stack>
                    <Chip variant='soft' color='success'>Active</Chip>
                </Stack>
            </Stack>
            <Stack p={1} sx={{ width: 84 }}>
                <Stack>
                    <Text level='title-sm' textAlign={"center"}>{item?.retailers?.length ?? 0}</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}


export default BeatListView