


import React from 'react'
import EditUserProfileModal, { EditProfileView } from '../../components/EditUserProfile'
import { Box } from '@mui/joy'
import Text from '../../components/Text'
import ZoomImage from '../../components/ZoomImage'

const ProfilePage = () => {
    
  return (
    <Box sx={{padding: 3}}>
        <Text level="h3" pb={2.5}>Edit Profile</Text>
        <EditProfileView userId={1234} />
    </Box>
  )
}

export default ProfilePage