

import React from 'react'
import Table from '../Table'
import { Avatar, Chip, IconButton, Stack } from '@mui/joy'
import { MoreHorizRounded } from '@mui/icons-material'
import Text from '../Text'
import { createColumnHelper } from '@tanstack/react-table'
import { useAppContext } from '../../hooks/useAppContext'
import { useGetNearbyPlaceQuery, useGetProductCategoryQuery } from '../../store/query'
import { renderMenu } from '../ContextMenu'
import ListFilterView from './ListFilterView'


const columnHelper = createColumnHelper()

const ListView = () => {
    const app = useAppContext()
    const places = useGetNearbyPlaceQuery()

    const onRowClicked = (event, row) => {
        const contextMenuData = [
            {
                id : 1,
                title : "Change Role",
                onClick : () => app.editUserRoleModalRef?.current?.open(row)
            },
            {
                id : 1,
                title : "Mark as Inactive"
            }
        ]
        app.contextMenuRef?.current?.open({
            data : renderMenu(contextMenuData),
            top: event.clientY + 1,
            left : event.clientX + 1,
        })
    }


const columns = [
    columnHelper.accessor("id", {
        header : "Sr",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>{props.row.index + 1}</Text>
            )
        },
        enableResizing : true,
        size: 48
    }),
    columnHelper.accessor("name", {
        header : "Name",
        cell : props => {
            console.log("pro", props);
            return(
                <Stack direction={"row"} alignItems={"center"}>
                    <Avatar src={props.row.original?.imageUrl} alt={props.getValue()} size='sm' variant='soft' color='neutral' />
                    <div>
                        <Text sx={{ padding: "0 12px 0 12px" }}>{props.getValue()}</Text>
                    </div>
                </Stack>
            )
        }
    }),
    columnHelper.display({
        id: "actions",
        cell : props => {
            return(
                <IconButton size="sm" onClick={(event) => onRowClicked(event, props.row.original)}>
                    <MoreHorizRounded fontSize="small" />
                </IconButton>
            )
        }
    })
]

    return (
        <>
            <Table 
                data={places.data ?? []}
                loading={places.isLoading}
                error={places.isError}
                onRowClicked={onRowClicked}
                HeaderComponent={ListFilterView}
                showFooter
                rowHeight={56}
                columns={columns} />
        </>
    )
}

export default ListView