

import { Button, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'

const PageHeaderView = () => {
  return (
    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{backgroundColor: "#fff", px: 3, py: 2}}>
        <Stack>
            <Text level='h3' pb={0.5}>Header</Text>
            <Text level='body-xs'>Path 1 &gt; JP Beat &gt; </Text>
        </Stack>
        <Stack>
            <Button>Download</Button>
        </Stack>
    </Stack>
  )
}

export default PageHeaderView