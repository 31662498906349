


import { RotateLeftRounded, RotateRightRounded, ZoomInRounded, ZoomOutRounded } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/joy'
import React, { useEffect, useState } from 'react'
import Text from '../Text'

const ImageTagManager = (props) => {
    const [state, setState] = useState({
        scale: 1,
        rotate: 0
    })
    const onRotateLeft = () => {
        setState(state => ({ ...state, rotate: state.rotate - 90 }))
    }
    const onRotateRight = () => {
        setState(state => ({ ...state, rotate: state.rotate + 90 }))
    }
    const onZoomIn = () => {
        setState(state => ({ ...state, scale: state.scale + 0.4 }))
    }
    const onZoomOut = (e) => {
        setState(state => ({ ...state, scale: state.scale - 0.4 }))
        e?.preventDefault()
    }
    const resetZoom = () => {
        setState(state => ({ ...state, scale: 1 }))
    }

    const onChange = async(event) => {
        console.log("files", event.target.files[0]);
        const file = event.target.files[0]
        // const model = await tf.loadLayersModel({load : require("../../assets/model/text-detection.tflite")})
        // console.log("model", model);
        // event.target.value = null
    }

    return (
        <>
            <Stack flex={1} justifyContent={"center"} sx={{ overflow: "scroll", cursor: "zoom-in", borderWidth: 1, borderColor: "red" }} onClick={onZoomIn} onContextMenu={onZoomOut}>
                <img key={"sku-action"} alt="sku-image" src={props.src} style={{ ...props.style, transition: "ease 0.2s", transform: `scale(${state.scale}) rotate(${state.rotate}deg)` }} />
            </Stack>
            <div style={{ position: "absolute", bottom: 0, width: "100%", height: 40, backgroundColor: "#fff" }}>
                <Stack flex={1} flexDirection={"row"} alignItems={"center"} sx={{ height: "100%", px: 1 }}>
                    <IconButton onClick={onRotateLeft} size='sm'>
                        <RotateLeftRounded />
                    </IconButton>
                    <IconButton onClick={onRotateRight} size='sm'>
                        <RotateRightRounded />
                    </IconButton>
                    <Stack flex={1} />
                    {/* <input type='file' onChange={onChange} /> */}
                    <IconButton size='sm' onClick={onZoomIn}>
                        <ZoomInRounded />
                    </IconButton>
                    <Text level='body-xs' px={0.5} sx={{ width: "40px", textAlign: "center", cursor: "pointer" }} onClick={resetZoom}>{parseInt(state.scale * 100)}%</Text>
                    <IconButton size='sm' onClick={onZoomOut}>
                        <ZoomOutRounded />
                    </IconButton>
                </Stack>
            </div>
        </>
    )
}

export default ImageTagManager