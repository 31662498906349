import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/authReducer";
import { query } from "./query";
import localforage from "localforage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import { tableReducer } from "./reducers/tableReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    table : tableReducer,
    [query.reducerPath]: query.reducer
})

const persistConfig = {
    key: 'root',
    storage: localforage,
    blacklist: ['api']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const Store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
        }).concat(query.middleware)
    }
})

export const persistor = persistStore(Store)


export const dispatch = Store.dispatch
export default Store