


import { ArrowDownwardRounded, Dashboard, DashboardRounded, KeyboardArrowDownRounded, KeyboardArrowRight, KeyboardArrowUpRounded, LogoutRounded } from '@mui/icons-material'
import { Box, Button, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Sheet, Stack } from '@mui/joy'
import React, { useCallback, useMemo, useState } from 'react'
import Text from '../Text'
import { navData } from './data'
import { useLocation, useNavigate } from 'react-router-dom'
import { logoutUser } from '../../store/actions/authAction'
import { access } from '../Accessible/access.config'
import { useGetUserInfoQuery } from '../../store/query'

const DrawerContent = () => {

    const user = useGetUserInfoQuery("")

    const navLink = useMemo(() => {
        return navData
        let scopes = []
        const roles = user.data?.userRole ?? []
        roles.forEach(item => {
            console.log("scopes", item, user.data);
            if(access[item]) scopes = [...scopes, ...access[item]]
        })
        console.log("scopes", scopes);
        return navData.filter(item => {
            return scopes.includes(item.name)
        }) ?? []
    }, [user.data])

    const renderLink = (item, index) => <NavItem item={item} index={index} />
    const renderNavItems = navLink?.map(renderLink)
  return (
    <Sheet 
        variant='solid' 
        color='primary'
        invertedColors
        sx={{height: window.innerHeight, overflow: "hidden"}}>
        <Stack sx={{height: "100%"}}>
            <Box sx={{padding: "16px"}}>
                <img src={require("../../assets/images/logo.png")} alt="logo.png" style={{width: "100%"}} />
            </Box>
            <Stack flex={1} overflow={"scroll"}>
                <List size="md" variant='plain' color='neutral' component={"nav"} sx={{padding: "0 16px 0 16px"}}>
                    {renderNavItems}
                </List>
            </Stack>
            {/* <Stack sx={{position: "relative", height: 1, width: "100%"}}>
                <Stack sx={{height: 12, width: "100%"}}></Stack>
            </Stack> */}
            <Stack px={2} justifyContent={"flex-end"} sx={{height: 144, pb: 3}}>
                <Button 
                    onClick={logoutUser}
                    variant='soft' 
                    color='danger' 
                    size='sm' 
                    fullWidth 
                    startDecorator={<LogoutRounded fontSize='small' sx={{marginRight: 0.5}} />}
                    >Logout
                </Button>
            </Stack>
        </Stack>
    </Sheet>
  )
}

const NavItem = ({item, index}) => {
    const [collapsed, setCollapsed] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    
    const selected = useMemo(() => {
        if(location.pathname == item.path) return true
        return false
    }, [location])
    const renderChild = useCallback((item, index) => <NavItem item={item} index={index} />, [])
    const handleClick = () => {
        if(item.child){
            return
            return setCollapsed(!collapsed)
        }
        navigate(item.path)
    }
    if(item.child){
        return (
            <>
                <ListItem key={item.path} sx={{marginBottom: "6px"}}>
                    <ListItemButton variant='plain' onClick={handleClick} selected={selected} sx={{borderRadius: 6}}>
                        <ListItemDecorator>
                            {item.icon}
                        </ListItemDecorator>
                        <Stack flex={1} sx={{minWidth: 100}}>
                            <ListItemContent>{item.title}</ListItemContent>
                        </Stack>
                        <ListItemDecorator>
                            {
                                collapsed ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />
                            }
                        </ListItemDecorator>
                    </ListItemButton>
                </ListItem>
                <Stack sx={{transition: "ease", overflow: "hidden", display: collapsed ? "none" : "auto"}}>
                    {item.child.map(renderChild)}
                </Stack>
            </>
        )
    }
    return(
        <ListItem key={item.path} sx={{marginBottom: "6px"}}>
            <ListItemButton variant='plain' onClick={handleClick} selected={selected} sx={{borderRadius: 6}}>
                {
                    <ListItemDecorator>{item.icon}</ListItemDecorator>
                }
                <Stack flex={1}>
                    <ListItemContent>{item.title}</ListItemContent>
                </Stack>
            </ListItemButton>
        </ListItem>
    )
}

export default DrawerContent