


import React from 'react'
import UserRoleManagementList from '../../components/UserRoleManagementList'
import { Box, Button, Stack } from '@mui/joy'
import { useGetUserListQuery } from '../../store/query'
import Text from '../../components/Text'
import { AddRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'

const ManageUserRolePage = () => {

  const app = useAppContext()
  const onAddUserClick = () => {
    app.addUserModalRef.current?.open()
  }
  return (
    <Box px={2} py={3}>
      <Box>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Text level="h3">Manage User</Text>
          <Button
            onClick={onAddUserClick}
            startDecorator={<AddRounded fontSize='small' />}>Add User</Button>
        </Stack>
      </Box>
      <UserRoleManagementList />
    </Box>
  )
}

export default ManageUserRolePage