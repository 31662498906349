import { AccountCircleRounded, ApartmentRounded, DashboardRounded, HailRounded, Inventory2Rounded, LensBlurRounded, Person4Rounded, ReceiptRounded, StoreOutlined, StoreRounded, StorefrontOutlined, StorefrontRounded, UploadFileRounded, WarehouseRounded } from "@mui/icons-material";
import { actions } from "../Accessible/access.config";


const style = {fontSize: 24}

export const navData = [
    {
        title : "Dashboard",
        icon : <DashboardRounded sx={style} />,
        path : "/",
        name : actions.viewDashboard
    },
    // {
    //     title : "Sales Agents",
    //     icon : <Person4Rounded sx={style} />,
    //     path : "/sales-representatives",
    //     name : actions.viewSalesPersonActivity
    // },
    {
        title : "Label Image",
        icon : <LensBlurRounded sx={style} />,
        path : "/store-sku-activity-action",
        name : actions.manageRetailStore
    },
    {
        title : "Organization",
        icon : <ApartmentRounded sx={style} />,
        name : actions.manageRetailStore,
        child : [
            {
                title : "Outlets",
                path : "/stores",
                name : actions.manageRetailStore,
            },
            {
                title : "Beats",
                path : "/retail-beat",
                name : actions.manageRetailStore,
            },
            // {
            //     title : "Distributors",
            //     path : "/distributors",
            //     name : actions.manageRetailStore,
            // },
            // {
            //     title : "Warehouses",
            //     path : "/warehouses",
            //     name : actions.manageRetailStore,
            // },
        ]
    },
    {
        title : "Inventory",
        icon : <Inventory2Rounded sx={style} />,
        name : actions.manageRetailStore,
        child : [
            // {
            //     title : "Products",
            //     path : "/products",
            //     name : actions.manageRetailStore,
            // },
            {
                title : "Brand",
                path : "/brands",
                name : actions.manageRetailStore,
            },
            // {
            //     title : "Category",
            //     path : "/sku-category",
            //     name : actions.manageRetailStore,
            // },
            // {
            //     title : "Nearby Places",
            //     path : "/nearby-places",
            //     name : actions.manageBrand
            // },
        ]
    },
    {
        title : "Orders",
        icon : <ReceiptRounded sx={style} />,
        path : "/orders",
        name : actions.bulkUpload
    },
    {
        title : "Users",
        icon : <AccountCircleRounded sx={style} />,
        name : actions.mangeOwnProfile,
        child : [
            {
                title : "All User",
                path : "/manage-user-role",
                name : actions.mangeOwnProfile,
            },
            // {
            //     title : "Role",
            //     path : "/manage-user-role1",
            //     name : actions.mangeOwnProfile,
            // },
            // {
            //     title : "Designation",
            //     path : "/manage-user-role2",
            //     name : actions.mangeOwnProfile,
            // }
        ]
    },
    {
        title : "Bulk Upload",
        icon : <UploadFileRounded sx={style} />,
        path : "/bulk-upload",
        name : actions.bulkUpload
    },
    {
        title : "My Profile",
        icon : <AccountCircleRounded sx={style} />,
        path : "/profile",
        name : actions.mangeOwnProfile
    }
]