

import { KeyboardArrowLeft, KeyboardArrowLeftRounded, KeyboardArrowRight, KeyboardArrowRightRounded } from '@mui/icons-material'
import { Box, Button, IconButton, iconButtonClasses } from '@mui/joy'
import React, { useMemo } from 'react'

const Footer = ({table}) => {
    const pageCount = table.getPageCount()
    const pageNav = useMemo(() => {
        if(pageCount > 6){
            return [1,2,3,"...", pageCount - 2, pageCount - 1, pageCount]
        }
        return Array(pageCount).fill(1)?.map((item, index) => index + 1)
    }, [pageCount])
    console.log("pageCOunt", pageCount);
    return (
        <Box
            className="Pagination-laptopUp"
            sx={{
                pt: 2,
                gap: 1,
                [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
                display: {
                    xs: 'none',
                    md: 'flex',
                },
            }}
        >
            <Button
                size="sm"
                variant="outlined"
                color="neutral"
                startDecorator={<KeyboardArrowLeftRounded />}
                disabled={!table.getCanPreviousPage()}
                onClick={table.previousPage}
            >
                Previous
            </Button>

            <Box sx={{ flex: 1 }} />
            {pageNav?.map((page) => (
                <IconButton
                    key={page}
                    size="sm"
                    variant={Number(page) ? 'outlined' : 'plain'}
                    sx={{backgroundColor: "#fff"}}
                    disabled={page == "..." ? true : false}
                    onClick={() => table.setPageIndex(page - 1)}
                >
                    {page}
                </IconButton>
            ))}
            <Box sx={{ flex: 1 }} />

            <Button
                size="sm"
                variant="outlined"
                color="neutral"
                endDecorator={<KeyboardArrowRightRounded />}
                onClick={table.nextPage}
                disabled={!table.getCanNextPage()}
            >
                Next
            </Button>
        </Box>
    )
}

export default Footer