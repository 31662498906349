

import React from 'react'
import Table from '../Table'
import Text from '../Text'
import { Button, Checkbox, Chip, IconButton, Input, Option, Select, Stack } from '@mui/joy'
import { createColumnHelper } from '@tanstack/react-table'
import { CloseRounded, MoreHorizRounded, SearchRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { renderMenu } from '../ContextMenu'
import Link from '../Link'
import { useGetOrderListQuery, useGetStoreListQuery } from '../../store/query'
import CustomTable from '../Table/CustomTable'
import moment from 'moment'
import { parseTimeArray } from '../../utils'
import FilterChip from '../Table/FilterChip'
// import { SelectStore } from '../Store'
// import { SelectDateRange } from '../Selection'

const columnHelper = createColumnHelper()

const ListView = () => {

    const app = useAppContext()

    const getDataFromQuery = (response) => {
        return response?.data
    }
    const getEncodedFilter = (filter) => {
        let string = "", expression = ""
        if(filter.currentPage) string = `&page=${filter.currentPage}`
        if(filter.search) expression += `name==${filter.search};`
        return string + (expression ? "&filterExpression="+expression : "")
    }

    const getTotalPages = response => {
        return response?.pagination.totalPages
    }

    return(
        <CustomTable
                name='MasterOrderTable'
                query={useGetStoreListQuery}
                getDataFromQuery={getDataFromQuery}
                filter={{param: 12345}}
                height={480}
                getEncodedFilter={getEncodedFilter}
                getTotalPages={getTotalPages}
                HeaderComponent={ListHeaderComponent}
                renderItem={ListItemView}
            />
    )
}




export const ListHeaderComponent = (props) => {
    
    return (
        <Stack>
            {/* <Stack pb={3}>
                <Text level='title-md' pb={1}>Filter</Text>
                <Stack flexDirection={"row"} gap={2}>
                    <Select placeholder="User" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                </Stack>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <FilterChip filter={props.filter} handleFilterChange={props.handleFilterChange} />
                </Stack>
            </Stack> */}
            <Stack pb={2}>
                <Text level='title-md' pb={0.4}>All List</Text>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <Input
                        startDecorator={<SearchRounded />}
                        onChange={props.handleSearchInput}
                        sx={{flex: 1, maxWidth: 1000}}
                        />
                    <Stack flex={1} />
                    <Button>Download</Button>
                </Stack>
            </Stack>
            <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} borderTop={"1px solid #ededed"} sx={{backgroundColor: "#F7F8FA"}}>
                <Stack p={1} px={2} sx={{width: 20}}>
                    <Stack>
                        <Checkbox size='sm' />
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 128}}>
                    <Stack>
                        <Text level='title-sm'>Outlet ID</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{flex: 1, maxWidth: 360}}>
                    <Stack>
                        <Text level='title-sm'>Outlet Name</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Outlet Area</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Pincode</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Outlet Type</Text>
                    </Stack>
                </Stack>
                {/* <Stack p={1} sx={{width: 100}}>
                    <Stack flex={1}>
                        <Text level='title-sm'>Warehouse/Distributor</Text>
                    </Stack>
                </Stack> */}
                {/* <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Beat</Text>
                    </Stack>
                </Stack> */}
            </Stack>
        </Stack>
    )
  }


export const ListItemView = (item, index) => {
    return (
      <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} backgroundColor="#fff">
        <Stack p={2} sx={{width: 20}}>
            <Stack>
                <Checkbox size='sm' />
            </Stack>
        </Stack>
          <Stack p={1} sx={{width: 128}}>
              <Stack>
                <Link to={`/store/${item?.id}`}>
                  <Text level='title-sm'>{item?.erpId}</Text>
                </Link>
                  {/* <Text level='body-sm'>Cyber Beat, Haryana</Text> */}
              </Stack>
          </Stack>
          <Stack p={1} sx={{flex: 1, maxWidth: 360}}>
              <Stack>
                    <Link to={`/store/${item?.id}`}>
                        <Text level='title-sm'>{item?.name}</Text>
                    </Link>
                  <Text level='body-sm'>{item?.retailerAddress?.buildingName}</Text>
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack>
                {/* <Link to={`/store/${item?.outletId}`}> */}
                  <Text level='title-sm'>Gurgaon</Text>
                {/* </Link> */}
                  {/* <Text level='body-sm'>Cyber Beat, Haryana</Text> */}
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack>
                  <Text level='title-sm'>{item?.retailerAddress?.pincode}</Text>
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack>
                  <Text level='title-sm'>{item?.retailerType}</Text>
              </Stack>
          </Stack>
          {/* <Stack p={1} sx={{width: 100}}>
              <Stack>
                  <Text level='title-sm'>Delivered</Text>
                  <Text level='body-sm'>12:13 PM 26/02/2024</Text>
              </Stack>
          </Stack> */}
      </Stack>
    )
  }

export default ListView