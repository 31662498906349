


import { Avatar, Box, Button, Chip, CircularProgress, FormControl, FormLabel, Input, Modal, Sheet, Stack } from '@mui/joy'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Text from '../Text'
import { AccountCircleRounded } from '@mui/icons-material'
import useUserProfileForm from '../../hooks/useUserProfileForm'
import { Controller, useFieldArray } from 'react-hook-form'
import { useUpdateProfilePictureMutation } from '../../store/query'
import toast from 'react-hot-toast'
import Placeholder from './Placeholder'

const EditProfileView = (props={
    userId : 0
}) => {
    const profilePicRef = useRef()
    const [selectedAddress, setSelectedAddress] = useState(0)
    
    const form = useUserProfileForm()
    const [updatePhoto] = useUpdateProfilePictureMutation()
    const addressField = useFieldArray({
        control : form.control,
        name: "address"
    })

    useEffect(() => {
        if(addressField.fields.length > 0 && selectedAddress == null){
            setSelectedAddress(addressField.fields?.[0]?.label)
        }
    }, [addressField.fields])

    if(form.profile.isLoading){
        return(
            <Placeholder />
        )
    }

    return (
        <Sheet variant='plain' color='neutral' sx={{ width: "900px", height: "80%", maxHeight: "700px", minHeight: "500px", padding: 3, borderRadius: 6 }}>
            <Box flex={1}>
                {/* <Text level='h4' pb={3}>Edit Profile</Text> */}
                <Box>
                    <Stack flexDirection={"row"}>
                        <Controller
                            control={form.control}
                            name='photo'
                            render={({field: {value, onChange}, fieldState: {error}}) => {
                                const onClick = () => {
                                    profilePicRef.current?.click()
                                }
                                const onSelect = (event) => {
                                    onChange(event.target.files[0])
                                    console.log("hghg", event.target.files);
                                    const formData = new FormData()
                                    formData.append("profilePic", event.target.files[0] )
                                    toast.promise(updatePhoto(formData), {
                                        loading: "Uploading Profile Picture",
                                        success: "Uploaded Profile Picture"
                                    })
                                }
                                return (
                                    <Box sx={{ display: "flex", width: "180px", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                        <input ref={profilePicRef} type="file" accept="image/png, image/jpeg, image/jpg" onChange={onSelect} style={{display: "none"}} />
                                        <Avatar size='lg' src={value} sx={{ width: "96px", height: "96px" }} />
                                        <Button size='sm' variant='plain' onClick={onClick}>Change Photo</Button>
                                        {/* <Text>Change Photo</Text> */}
                                    </Box>
                                )
                            }}
                            />
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-end", gap: 2, flex: 1 }}>
                            <Box sx={{ maxWidth: "300px" }}>
                                <FormControl>
                                    <FormLabel>First Name</FormLabel>
                                    <Controller
                                        control={form.control}
                                        name='firstName'
                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                            <Input placeholder='Enter name' value={value} onChange={e => onChange(e.target.value)} />
                                        )}
                                        />
                                </FormControl>
                            </Box>
                            <Box sx={{ maxWidth: "300px" }}>
                                <FormControl>
                                    <FormLabel>Last Name</FormLabel>
                                    <Controller
                                        control={form.control}
                                        name='lastName'
                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                            <Input placeholder='Enter last name' value={value} onChange={e => onChange(e.target.value)} />
                                        )}
                                        />
                                </FormControl>
                            </Box>
                            <Box sx={{ maxWidth: "300px" }}>
                                <FormControl>
                                    <FormLabel>Display Name</FormLabel>
                                    <Controller
                                        control={form.control}
                                        name='firstName'
                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                            <Input value={value?.split(" ")?.[0] ?? ""} disabled />
                                        )}
                                        />
                                </FormControl>
                            </Box>
                            <Box sx={{ maxWidth: "300px" }}>
                                <FormControl>
                                    <FormLabel>Email</FormLabel>
                                    <Controller
                                        control={form.control}
                                        name='email'
                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                            <Input placeholder='Enter email' value={value} onChange={e => onChange(e.target.value)} />
                                        )}
                                        />
                                </FormControl>
                            </Box>
                            <Box sx={{ maxWidth: "300px" }}>
                                <FormControl>
                                    <FormLabel>Phone</FormLabel>
                                    <Controller
                                        control={form.control}
                                        name='phoneNumber'
                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                            <Input placeholder='Enter phone' value={value} onChange={e => onChange(e.target.value)} />
                                        )}
                                        />
                                </FormControl>
                            </Box>
                            <Box sx={{ maxWidth: "300px", opacity: 0 }}>
                                <FormControl>
                                    <FormLabel>Role</FormLabel>
                                    <Input placeholder='Enter role' disabled />
                                </FormControl>
                            </Box>
                        </Box>
                    </Stack>
                    <Box pt={3}>
                        <Text level='h4' mb={2}>Contact Address</Text>
                        <Stack flexDirection={"row"} gap={1} mb={1.5}>
                            {
                                addressField.fields?.map((item, index) => {
                                    const onClick = () => {
                                        setSelectedAddress(index)
                                    }
                                    if(selectedAddress == index){
                                        return <Chip variant='solid' color='primary' onClick={onClick}>{item.label}</Chip>
                                    }
                                    return <Chip variant='outlined' onClick={onClick}>{item.label}</Chip>
                                })
                            }
                        </Stack>
                        {
                            addressField.fields?.map((item, index) => {
                                if(selectedAddress != index) return null
                                return(
                                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2, flex: 1 }}>
                                        <Box sx={{ width: "100%", maxWidth: "500px" }}>
                                            <FormControl>
                                                <FormLabel>Address Line 1</FormLabel>
                                                <Controller
                                                    control={form.control}
                                                    name={`address.${index}.buildingName`}
                                                    render={({field: {value, onChange}, fieldState: {error}}) => (
                                                        <Input placeholder='Enter apartment/building name' value={value} onChange={e => onChange(e.target.value)} />
                                                    )}
                                                    />
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <Box sx={{ width: "100%", maxWidth: "500px" }}>
                                                <FormControl>
                                                    <FormLabel>Address Line 2</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name={`address.${index}.area`}
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <Input placeholder='Enter area/sector' value={value} onChange={e => onChange(e.target.value)} />
                                                        )}
                                                        />
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Box sx={{ maxWidth: "300px" }}>
                                            <FormControl>
                                                <FormLabel>City</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name={`address.${index}.city`}
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <Input placeholder='Enter city' value={value} onChange={e => onChange(e.target.value)} />
                                                        )}
                                                        />
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ maxWidth: "300px" }}>
                                            <FormControl>
                                                <FormLabel>State</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name={`address.${index}.state`}
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <Input placeholder='Enter state' value={value} onChange={e => onChange(e.target.value)} />
                                                        )}
                                                        />
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ maxWidth: "300px" }}>
                                            <FormControl>
                                                <FormLabel>Country</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name={`address.${index}.country`}
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <Input placeholder='Enter country' value={value} disabled onChange={e => onChange(e.target.value)} />
                                                        )}
                                                        />
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ maxWidth: "300px" }}>
                                            <FormControl>
                                                <FormLabel>Pincode</FormLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name={`address.${index}.pincode`}
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <Input placeholder='Enter pincode' value={value} onChange={e => onChange(e.target.value)} />
                                                        )}
                                                        />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <Box pt={2} sx={{ textAlign: "center" }}>
                <Button onClick={form.onSubmit}>Submit</Button>
            </Box>
        </Sheet>
    )
}

export default EditProfileView