

import React from 'react'
import BasicDetail from '../../components/EmployeeDetail/BasicDetail'
import ActivityList from '../../components/StoreSkuActivity/ActivityList'
import Text from '../../components/Text'
import { Box } from '@mui/joy'

const EmployeeDetailPage = () => {
  return (
    <Box px={3}>
        <BasicDetail />
        <div>
            <Text level={"title-md"} py={2}>SKU Activity</Text>
            <ActivityList />
        </div>
    </Box>
  )
}

export default EmployeeDetailPage