

const initialState = {
    isLogin : false,
    token : null,
    // token : "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyYXZpQGZhaXJkZWFsLm1hcmtldCIsInJvbGVzIjpbIkFETUlOIl0sImlhdCI6MTcwNjEwNjM3NiwiZXhwIjoxNzA3NDAyMzc2fQ.eAjoyGAmV27mVtGkMqpEkFNzRZi88tyK2QG_YpYZRe0",
    lastTokenRefreshed : 0,
    role : []
}

export const ActionType = {
    LOGIN_USER : "auth/login",
    LOGOUT_USER : "auth/logout",
    RESET : "reset"
}


export const authReducer = (state=initialState, action) => {
    switch(action.type){
        case ActionType.LOGIN_USER : return {...state, ...action.payload}
        case ActionType.LOGOUT_USER : return initialState
        case ActionType.RESET : return initialState
        default: return state
    }
}