

import React, { useEffect, useState } from 'react'
import Text from '../../components/Text'
import { Box, Button, CircularProgress, Divider, FormControl, FormHelperText, FormLabel, Input, Stack } from '@mui/joy'
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuthentication } from '../../hooks/useLogin'

const LoginPage = () => {
    const auth = useAuthentication()

    const OtpInputDecorator = () => {
        const [timer, setTimer] = useState(30)
        const onResendOtp = () => {
            auth.requestOtp()
            setTimer(30)
        }
        useEffect(() => {
            let clock = setTimeout(() => {
                if(timer > 0) setTimer(timer - 1)
            }, 1000)
            return () => {
                clearTimeout(clock)
            }
        }, [timer])
        if(!(auth.status == 1)) return null
        if(timer == 0){
            return(
                <Button variant='plain' color='neutral' onClick={onResendOtp}>
                    <Text level='body-xs'>Send OTP</Text>
                </Button>
            )
        }
        return(
            <CircularProgress color="neutral" thickness={3} determinate value={timer*100/30} sx={{ '--CircularProgress-size': '28px', transform: "scale(0.8)" }}>
                <Text level='body-xs'>{timer}</Text>
            </CircularProgress>
        )
    }

  return (
    <Box flex={1} height={"100vh"}>
        <Stack direction={'row'} height={"100%"}>
            <Box flex={1} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Box sx={{minWidth: 360, height: 128, textAlign: "center"}} >
                    <img src={require("../../assets/images/logo-login.png")} alt="fairdeal-logo" width={180} />
                    <Text level='body-xs' fontWeight={800} mb={3} sx={{textAlign: "center", fontWeight: "500", py: 0.4}}>To Be In Every Shopping Bag Of The World</Text>
                </Box>
                <Box sx={{maxWidth: 360, width: "100%", minHeight: 400}}>
                    <Text level='title-lg' fontWeight={800} mb={3} mt={2} sx={{textAlign: "center"}}>Login to your Account</Text>
                    <FormControl sx={{marginBottom: "12px"}}>
                        {/* <FormLabel>Phone</FormLabel> */}
                        <Input 
                            size='sm' 
                            variant='soft' 
                            color='neutral' 
                            disabled={auth.status == 1}
                            value={auth.phone} 
                            onChange={e => auth.setPhone(e.target.value)}
                            placeholder="Enter Phone" />
                        {/* <FormHelperText>This is a helper text.</FormHelperText> */}
                    </FormControl>
                    <FormControl sx={{marginBottom: "20px"}}>
                        {/* <FormLabel>OTP</FormLabel> */}
                        <Input 
                            size='sm' 
                            variant='plain'           
                            disabled={!(auth.status == 1)} 
                            color='neutral' 
                            value={auth.otp}
                            onChange={e => auth.setOtp(e.target.value)}
                            endDecorator={<OtpInputDecorator />}
                            placeholder="Enter OTP" />
                        {/* <FormHelperText>This is a helper text.</FormHelperText> */}
                    </FormControl>
                    <Box>
                        {
                            auth.status == 1 ?
                                <Button loading={auth.authenticating} onClick={auth.verifyOtp} variant='solid' color="primary" fullWidth={"100%"} >Verify OTP</Button> 
                                    :
                                <Button loading={auth.authenticating} onClick={auth.requestOtp} variant='solid' color="primary" fullWidth={"100%"} >Send OTP</Button>
                        }
                    </Box>
                    <Stack flex={1} sx={{width: "100%", pt: 6}}>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                            <a href="https://fairdeal.market/privacy-policy" style={{textDecoration: "none"}}>
                                <Text level='body-xs'>Privacy Policy</Text>
                            </a>
                            <Text level='body-xs' px={1}>•</Text>
                            <a href="https://fairdeal.market/terms-and-conditions" style={{textDecoration: "none"}}>
                                <Text level='body-xs'>Terms & Conditions</Text>
                            </a>
                        </Stack>
                    </Stack>
                    {/* <Stack flexDirection={"row"} alignItems={"center"} my={3}>
                        <div style={{flex: 1}}>
                            <Divider sx={{flex: 1}} />
                        </div>
                        <Text level='body-xs' color='neutral' px={2}>Or login with</Text>
                        <div style={{flex: 1}}>
                            <Divider sx={{flex: 1}} />
                        </div>
                    </Stack>
                    <Button loading={auth.authenticating} onClick={auth.loginWithGoogle} startDecorator={<GoogleIcon fontSize={"small"} />} variant='outlined' color="primary" fullWidth={"100%"}>Log in with Google</Button> */}
                </Box>
            </Box>
            {/* <Box flex={1} sx={{backgroundColor: "#20222F"}} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Box pb={4}>
                    <img src={require("../../assets//images/landing-page-graphics.png")} alt="graphics" style={{width: "400px"}} />
                </Box>
                <Text fontSize={40} textColor='#fff' textAlign={"center"} fontWeight={"700"}>Did you know ?</Text>
                <Text fontSize={18} textColor='#3F435C' textAlign={"center"} fontWeight={"500"}>Otters hold hands so that they don’t drift apart in the ocean</Text>
            </Box> */}
        </Stack>
    </Box>
  )
}

export default LoginPage