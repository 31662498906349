
import ListView from "./ListView";
import Detail from "./Detail";
import CustomTable from "../Table/CustomTable";
import ProductListView from "./ProductListView";



export const OrderList = ListView
export const OrderDetail = Detail
export const OrderedProductListView = ProductListView