

import { Box } from '@mui/joy'
import React from 'react'
import StoreSkuActivity from '../../components/StoreSkuActivity'
import Text from '../../components/Text'

const StoreSkuActivityPage = () => {
  return (
    <Box sx={{padding:3}}>
        <Box>
            <Text level={"h3"}>Store SKU Activity</Text>
        </Box>
        <StoreSkuActivity showHeader={true} showFooter={true} />
    </Box>
  )
}

export default StoreSkuActivityPage