


import { CircularProgress, Sheet } from '@mui/joy'
import React from 'react'


const viewProps = {
    containerStyle: {},
    zoomPosition: "",
    zoomOffset : {}
}


const LoadingView = (props = viewProps) => {

    return (
        <Sheet
            variant="outlined"
            sx={[props.containerStyle, {justifyContent: "center", alignItems: "center"}]}
            >
            <CircularProgress />
        </Sheet>
    )
}

export default LoadingView