


import { Avatar, Box, Button, Chip, FormControl, FormLabel, Input, Modal, Sheet, Stack } from '@mui/joy'
import React, { useImperativeHandle, useState } from 'react'
import Text from '../Text'
import { AccountCircleRounded } from '@mui/icons-material'
import useUserProfileForm from '../../hooks/useUserProfileForm'
import { Controller } from 'react-hook-form'
import EditProfileViewComponent from './EditProfileView'

const EditUserProfileModal = React.forwardRef((props, ref) => {

    const [state, setState] = useState(null)

    const open = (data) => {
        setState(data)
    }

    const close = () => {
        setState(null)
    }

    useImperativeHandle(ref, () => {
        return {
            open,
            close
        }
    })

    return (
        <Modal
            open={state ? true : false}
            onClose={close}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <EditProfileViewComponent />
        </Modal>
    )
})

export const EditProfileView = EditProfileViewComponent

export default EditUserProfileModal