


import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RouteName } from './routes'
import LoginPage from '../pages/auth'
import Error404 from '../pages/error404'

const AuthRouter = () => {
  return (
    <Routes location={"/login"}>
        <Route path={RouteName.login} element={<LoginPage />}/>
        <Route path={"*"} element={<Error404 />}/>
    </Routes>
  )
}

export default AuthRouter