


import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, IconButton, Input, Option, Select, Sheet, Stack } from '@mui/joy'
import React, { useMemo } from 'react'
import Text from '../Text'
import { CloseRounded } from '@mui/icons-material'
import { Controller } from 'react-hook-form'
import useBrandForm from '../../hooks/useBrandForm'
import Accessible from '../Accessible'
import { actions } from '../Accessible/access.config'

const AddBrandView = (props) => {
    const { close } = props
    const form = useBrandForm({
        data : props.data,
        onSubmit : close
    })

    return (
        <Sheet sx={{ maxWidth: "500px", width: "100%", padding: 3, py: 1.5, borderRadius: "md" }}>
            <Accessible name={actions.manageBrand}>
                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} pb={1.5}>
                    <Text level='h4'>Add Brand</Text>
                    <IconButton onClick={close}>
                        <CloseRounded />
                    </IconButton>
                </Stack>
                <Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} pb={1.5}>
                        <Text>Category</Text>
                        <FormControl>
                            <Controller
                                control={form.control}
                                name="category"
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    const handleSelect = (e, value) => {
                                        onChange(value)
                                    }
                                    return (
                                        <Autocomplete
                                            multiple
                                            size='sm'
                                            placeholder='Select category'
                                            options={form.productCategory.data ?? []}
                                            getOptionLabel={option => option.name}
                                            value={value}
                                            onChange={handleSelect}
                                            sx={{ width: "300px" }}
                                        />
                                    )
                                }} />
                        </FormControl>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} pb={1.5}>
                        <Text>Brand Name</Text>
                        <FormControl>
                            <Controller
                                control={form.control}
                                name="name"
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input
                                        sx={{ width: "300px" }}
                                        value={value}
                                        onChange={e => onChange(e.target.value)} />
                                )} />
                        </FormControl>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} pb={1.5}>
                        <Text>Brand Logo</Text>
                        <FormControl>
                            <Controller
                                control={form.control}
                                name="logo"
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input
                                        sx={{ width: "300px" }}
                                        value={value}
                                        onChange={e => onChange(e.target.value)} />
                                )} />
                            {/* <Input type="file" sx={{ width: "300px" }} /> */}
                        </FormControl>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} pb={1.5}>
                        <Text>Popular</Text>
                        <FormControl sx={{ width: "300px" }}>
                            <Controller
                                control={form.control}
                                name="popular"
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Checkbox />
                                )} />
                        </FormControl>
                    </Stack>
                </Stack>
                <Stack py={2}>
                    <Button onClick={form.submit}>Submit</Button>
                </Stack>
            </Accessible>
        </Sheet>
    )
}

export default AddBrandView