


import { Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'

const UnauthorizedPlaceholder = () => {
  return (
    <Stack flex={1} sx={{alignItems: "center", justifyContent: "center"}}>
        <Text>You haven't got the access to view this content</Text>
    </Stack>
  )
}

export default UnauthorizedPlaceholder