

import React from 'react'
import Text from '../../components/Text'
import { Badge, Box, Button, Grid, IconButton, Sheet, Stack } from '@mui/joy'
import SkuActivityStatistics from '../../components/SkuActivityStatistics'
import StoreSkuActivity from '../../components/StoreSkuActivity'
import { Link } from 'react-router-dom'
import Accessible from '../../components/Accessible'
import { ArrowForwardRounded, ArrowRightRounded, KeyboardArrowRightRounded, ShoppingCartRounded } from '@mui/icons-material'
import ActivityMap from '../../components/ActivityMap'
import { SkuImageActivityList } from '../../components/SkuImageActivity'

const DashboardPage = () => {
  return (
    <Stack p={3}>
          <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Text level={"h4"}>Store Sku Activity</Text>
              <Link to="store-sku-activity-action">
                <Button variant='solid' endDecorator={<ArrowForwardRounded fontSize='small'/>}>
                  Continue Labelling
                </Button>
              </Link>
          </Stack>
          <SkuImageActivityList />
    </Stack>
  )
  return (
    // <Accessible>
      <Box sx={{position: "relative", padding: 3, height: "100%", width: "100%"}}>
        <Stack flexDirection={"row"}>
          {/* <Box>
            <SkuActivityStatistics />
          </Box> */}
        </Stack>
            {/* <Stack p={3} sx={{position: "absolute", zIndex: 1, right: 0, width: 42}}>
              <Box pb={3}>
                <Badge>
                  <ShoppingCartRounded fontSize='large' />
                </Badge>
              </Box>
              <Box pb={3}>
                <Badge>
                  <ShoppingCartRounded fontSize='large' />
                </Badge>
              </Box>
              <Box pb={3}>
                <Badge>
                  <ShoppingCartRounded fontSize='large' />
                </Badge>
              </Box>
            </Stack>
          <ActivityMap /> */}
          <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Text level={"h4"}>Store Sku Activity</Text>
              <Link to="store-sku-activity-action">
                <Button variant='solid' endDecorator={<ArrowForwardRounded fontSize='small'/>}>
                  Continue Labelling
                </Button>
              </Link>
          </Stack>
          <SkuImageActivityList />
      </Box>
    // </Accessible>
  )
}

export default DashboardPage