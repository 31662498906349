


import React, { useMemo } from 'react'
import { useGetUserInfoQuery } from '../../store/query'
import { CircularProgress, Modal } from '@mui/material'
import UnauthorizedPlaceholder from './UnauthorizedPlaceholder'
import Store from '../../store'
import { access } from './access.config'

const Accessible = (props = {
    name : ""
}) => {
    const user = useGetUserInfoQuery("")
    
    const hasAccess = useMemo(() => {
        return true
        let scopes = []
        const roles = user.data?.userRole ?? []
        roles.forEach(item => {
            if(access[item]) scopes = [...scopes, ...access[item]]
        })
        return scopes.includes(props.name)
    }, [user.data])
    // const 
    if(user.isLoading){
        return(
            <Modal
                open={true}
                sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress />
            </Modal>
        )
    }
    if(!hasAccess) return <UnauthorizedPlaceholder />
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default Accessible