


import { Avatar, Box, Button, IconButton, Modal, Option, Select, Sheet, Stack } from '@mui/joy'
import React, { useImperativeHandle, useState } from 'react'
import Text from '../Text'
import { CloseRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { useGetUserRoleQuery } from '../../store/query'
import EditUserRoleView from './EditUserRoleView'
import Modalize from '../Modalize'

const EditUserRoleModal = ({refs}) => <Modalize ref={refs} Component={EditUserRoleView} />

export default EditUserRoleModal