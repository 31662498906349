

import { SearchRounded } from '@mui/icons-material'
import { Input, Sheet, Stack } from '@mui/joy'
import React from 'react'

const SearchView = (props) => {
  return (
    <Sheet sx={{ width: 900, height: "100%", maxHeight: 700, borderWidth: 1, borderRadius: "lg" }}>
      <Stack sx={{height: 64}}>
        <Input
          startDecorator={<SearchRounded color='primary' fontSize='medium' />}
          placeholder='What are you looking for ?'
          style={{height: "100%", border: 0}} />
      </Stack>
    </Sheet>
  )
}

export default SearchView