
import PaymentMethodsComponent from "./PaymentMethods";
import StoreLoyaltyProgrammeComponent from "./StoreLoyaltyProgramme";
import StoreTimingComponent from "./StoreTiming";
import StoreBrandByCategoriesComponent from "./StoreBrandByCategories";







export const StoreTiming = StoreTimingComponent
export const StoreLoyaltyProgrammes = StoreLoyaltyProgrammeComponent
export const PaymentMethods = PaymentMethodsComponent
export const StoreBrandByCategories = StoreBrandByCategoriesComponent