


import React from 'react'
import { StoreSkuActivityActionView } from '../../components/SKUActivityActionModal'
import { Box, Stack } from '@mui/joy'
import { useLocation, useParams } from 'react-router-dom'

const StoreSkuActivityActionPage = () => {
  return (
    <Stack sx={{padding: 3, flex: 1}}>
        <Stack flex={1}>
            <StoreSkuActivityActionView
                zoomPosition="bottom"
                zoomOffset={{vertical : -20, horizontal : 0}}
                containerStyle={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    maxWidth: 1000,
                    borderRadius: 'md',
                    p: 3,
                    pt : 1
                }} />
        </Stack>
    </Stack>
  )
}

export default StoreSkuActivityActionPage