

import React from 'react'
import Table from '../Table'
import Text from '../Text'
import { Button, Checkbox, Chip, IconButton, Input, Option, Select, Stack } from '@mui/joy'
import { createColumnHelper } from '@tanstack/react-table'
import { CloseRounded, EditRounded, MoreHorizRounded, PersonSearchRounded, SearchRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { renderMenu } from '../ContextMenu'
import Link from '../Link'
import { useGetBrandQuery, useGetOrderListQuery, useGetStoreListQuery } from '../../store/query'
import CustomTable from '../Table/CustomTable'
import moment from 'moment'
import { parseTimeArray } from '../../utils'
import FilterChip from '../Table/FilterChip'
// import { SelectStore } from '../Store'
// import { SelectDateRange } from '../Selection'

const columnHelper = createColumnHelper()

const ListView = () => {

    const app = useAppContext()

    const getDataFromQuery = (response) => {
        return response
    }
    const getEncodedFilter = (filter) => {
        let string = "", expression = ""
        if(filter.currentPage) string = `&page=${filter.currentPage}`
        if(filter.search) expression += `name==${filter.search};`
        return string + (expression ? "&filterExpression="+ expression : "")
    }

    const getTotalPages = response => {
        // return response?.pagination.totalPages
        return parseInt(response?.length / 10) + 1
    }

    return(
        <CustomTable
                name='MasterOrderTable'
                query={useGetBrandQuery}
                getDataFromQuery={getDataFromQuery}
                filter={{param: 12345}}
                height={480}
                getEncodedFilter={getEncodedFilter}
                getTotalPages={getTotalPages}
                HeaderComponent={ListHeaderComponent}
                renderItem={ListItemView}
            />
    )
}




export const ListHeaderComponent = (props) => {
    
    return (
        <Stack>
            {/* <Stack pb={3}>
                <Text level='title-md' pb={1}>Filter</Text>
                <Stack flexDirection={"row"} gap={2}>
                    <Select placeholder="User" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                    <Select placeholder="Outlet" sx={{flex: 1}} />
                </Stack>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <FilterChip filter={props.filter} handleFilterChange={props.handleFilterChange} />
                </Stack>
            </Stack> */}
            {/* <Stack pb={2}>
                <Text level='title-md' pb={0.4}>All List</Text>
                <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} pt={1.2}>
                    <Input
                        startDecorator={<SearchRounded />}
                        onChange={props.handleSearchInput}
                        sx={{flex: 1, maxWidth: 1000}}
                        />
                    <Stack flex={1} />
                    <Button>Download</Button>
                </Stack>
            </Stack> */}
            <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} borderTop={"1px solid #ededed"} sx={{backgroundColor: "#F7F8FA"}}>
                <Stack p={1} px={2} sx={{width: 20}}>
                    <Stack>
                        <Checkbox size='sm' />
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 64}}>
                    <Stack>
                        <Text level='title-sm'>Sr. No.</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 64}}>
                    <Stack>
                        <Text level='title-sm'>Logo</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{flex: 1, maxWidth: 184}}>
                    <Stack>
                        <Text level='title-sm'>Name</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Categories</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Status</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 148}}>
                    <Stack>
                        <Text level='title-sm'>Popular</Text>
                    </Stack>
                </Stack>
                <Stack p={1} sx={{width: 100}}>
                    <Stack flex={1}>
                        <Text level='title-sm'>Actions</Text>
                    </Stack>
                </Stack>
                {/* <Stack p={1} sx={{width: 100}}>
                    <Stack>
                        <Text level='title-sm'>Beat</Text>
                    </Stack>
                </Stack> */}
            </Stack>
        </Stack>
    )
  }


export const ListItemView = (item, index) => {
    const app = useAppContext()

    const onRowClicked = (event) => {
        app.addBrandModalRef?.current?.open(item)
        // const row = item
        // const contextMenuData = [
        //     {
        //         id : 1,
        //         title : "Edit Brand",
        //         onClick : () => app.addBrandModalRef?.current?.open(row)
        //     }
        // ]
        // app.contextMenuRef?.current?.open({
        //     data : renderMenu(contextMenuData),
        //     top: event.clientY + 1,
        //     left : event.clientX + 1,
        // })
    }
    return (
      <Stack flexDirection={"row"} py={0.4} borderBottom={"1px solid #ededed"} backgroundColor="#fff">
        <Stack p={2} sx={{width: 20}}>
            <Stack>
                <Checkbox size='sm' />
            </Stack>
        </Stack>
          <Stack p={1} sx={{width: 64}}>
              <Stack>
                {/* <Link to={`/store/${item?.id}`}> */}
                  <Text level='body-sm'>{index + 1}</Text>
                {/* </Link> */}
                  {/* <Text level='body-sm'>Cyber Beat, Haryana</Text> */}
              </Stack>
          </Stack>
        <Stack p={1} sx={{width: 64}}>
            <Stack sx={{width: 48, aspectRatio: 1}}>
                <img src={item?.logo} alt={item.name} sx={{width: "100%", height: "100%"}} />
            </Stack>
        </Stack>
          <Stack p={1} sx={{flex: 1, maxWidth: 184}}>
              <Stack flexDirection={"row"} gap={1}>
                    {/* <Link to={`/store/${item?.id}`}> */}
                        <Text level='title-sm'>{item?.name}</Text>
                    {/* </Link> */}
                  {/* <Text level='body-sm'>{item?.retailerAddress?.buildingName}</Text> */}
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack>
                {/* <Link to={`/store/${item?.outletId}`}> */}
                  <Text level='body-sm'>{item?.categories}</Text>
                {/* </Link> */}
                  {/* <Text level='body-sm'>Cyber Beat, Haryana</Text> */}
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack>
                  <Text level='title-sm'>{item?.retailerAddress?.pincode}</Text>
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 148}}>
              <Stack>
                  <Text level='title-sm'>{item?.retailerType}</Text>
              </Stack>
          </Stack>
          <Stack p={1} sx={{width: 100}}>
              <Stack flexDirection={"row"} gap={1} sx={{transform: "scale(0.7)"}}>
                <IconButton variant='outlined' size='sm' onClick={onRowClicked}>
                    <EditRounded />
                </IconButton>
                {/* <IconButton variant='outlined' size='sm'>
                    <EditRounded />
                </IconButton> */}
              </Stack>
          </Stack>
      </Stack>
    )
  }

export default ListView