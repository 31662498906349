

import React from 'react'
import Text from '../Text'
import { CircularProgress } from '@mui/joy'
import { ErrorRounded } from '@mui/icons-material'

const Placeholder = (props={
    loading : true,
    error : "message",
    columns : []
}) => {
    if(props.loading){
        return(
            <tr>
              <td colSpan={props.columns?.length} style={{textAlign: "center"}}>
                <CircularProgress />
              </td>
            </tr>
        )
    }
    return (
        <tr>
          <td colSpan={props.columns?.length} style={{textAlign: "center"}}>
            <ErrorRounded fontSize='large' color='error' />
            <Text>Something went wrong</Text>
          </td>
        </tr>
    )
}

export default Placeholder