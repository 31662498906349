


import { Button, Stack } from '@mui/joy'
import React from 'react'
import Text from '../../components/Text'
import { useAppContext } from '../../hooks/useAppContext'
import { AddRounded } from '@mui/icons-material'
import Accessible from '../../components/Accessible'
import { actions } from '../../components/Accessible/access.config'
import { BrandList } from '../../components/Brand'

const BrandPage = () => {
    const app = useAppContext()

    const addNewBrand = () => {
        app.addBrandModalRef.current?.open({
            id: 0
        })
    }

  return (
    <Stack sx={{padding: 3}}>
        <Stack flexDirection={"row"} pb={2}>
            <Stack flex={1}>
                <Text level='h3'>Brands</Text>
            </Stack>
            <Button onClick={addNewBrand} startDecorator={<AddRounded fontSize='small' />}>Add Brand</Button>
        </Stack>
        <BrandList />
    </Stack>
  )
}

export default BrandPage