

import { Stack } from '@mui/joy'
import React from 'react'
import Text from '../../components/Text'
import { ProductDetail } from '../../components/Product'

const ProductDetailPage = () => {
  return (
    <Stack p={3}>
      <Stack flexDirection={"row"} pb={2}>
          <Stack flex={1}>
              <Text level='h3'>Product Detail</Text>
          </Stack>
          {/* <Button onClick={addNewBrand} startDecorator={<AddRounded fontSize='small' />}>Add Brand</Button> */}
      </Stack>
      <ProductDetail />
    </Stack>
  )
}

export default ProductDetailPage