


import { Autocomplete, AutocompleteOption, Avatar, Box, Button, Chip, Grid, Modal, Sheet, Stack, Step, StepIndicator, Stepper } from '@mui/joy'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Text from '../Text'
import ZoomImage from '../ZoomImage'
import { useGetBrandQuery, useGetStoreSkuActivityQuery } from '../../store/query'
import { CheckCircleRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import useSkuImageActivityForm from '../../hooks/useSkuImageActivityForm'
import { Controller } from 'react-hook-form'


const viewProps = {
    containerStyle: {},
    zoomPosition: "",
    zoomOffset : {}
}


const NoActivityView = (props = viewProps) => {

    return (
        <Sheet
            variant="outlined"
            sx={[props.containerStyle, {justifyContent: "center", alignItems: "center"}]}
            >
            <Text level="h2">Come back Later. No Images for now</Text>
        </Sheet>
    )
}

export default NoActivityView