

import { Avatar, Box, Chip, CircularProgress, FormControl, FormLabel, Input, Sheet, Skeleton, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'

const Placeholder = () => {
  return (
    <Sheet variant='plain' color='neutral' sx={{ width: "100%", maxWidth: "900px", height: "80%", maxHeight: "700px", minHeight: "500px", padding: 3, borderRadius: 6 }}>
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
        </Stack>
    </Sheet>
  )
}

export default Placeholder