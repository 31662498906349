


import React, { useState } from 'react'
import Text from '../Text'
import { List, ListItem, ListItemButton, ListItemContent, ListItemDecorator } from '@mui/joy'
import { ArrowForwardRounded, NavigateNext, NavigateNextRounded } from '@mui/icons-material'
import { Menu } from '@mui/material'
import { useAppContext } from '../../hooks/useAppContext'

const renderMenu = (data) => {
    return(
        <List size="sm" variant='plain' component={"nav"}>
            {
                data?.map(item => <ListItemView item={item} />)
            }
        </List>
    )
}

const ListItemView = ({item}) => {
    const app = useAppContext()
    const onClick = () => {
        app.contextMenuRef.current?.close()
        item.onClick()
    }
    return(
        <ListItem sx={{marginBottom: "2px", position: "relative"}} >
            <ListItemButton sx={{borderRadius: 0}} onClick={onClick}>
                <ListItemContent>{item.title}</ListItemContent>
                {/* {
                    item.child && <ListItemDecorator><NavigateNextRounded fontSize='small' /></ListItemDecorator>
                } */}
            </ListItemButton>
        </ListItem>
    )
}

export default renderMenu