


import { Box, Stack } from '@mui/joy'
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import React, { useMemo } from 'react'
import { useGetNearbyPlaceQuery } from '../../store/query'
import Text from '../Text'

const NearbyPlaces = ({data}) => {
    const places = useGetNearbyPlaceQuery()
    const list = useMemo(() => {
        if(data.length > 0){
            return places.data?.filter(place => {
                return data.find(item => item.id == place.id) ? true : false
            }) ?? []
        }
        return []
    }, [places.data, data])
    return (
        <Box>
            {
                list.length == 0 && (
                    <Box>
                        <Text>No landmarks added</Text>
                    </Box>
                )
            }
            <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} sx={{ width: "100%", height: 450 }} cols={12} rowHeight={64}>
                {list.map((item) => (
                    <Stack sx={{width: 80}}>
                        <Stack sx={{width: "100%", aspectRatio: 1}}>
                            <img src={item.imageUrl} alt={item.name} style={{objectFit:"contain", width: "100%", height: "100%"}} />
                        </Stack>
                        <Text level='body-sm' sx={{textAlign: "center", wordWrap: "break-word"}}>{item?.name}</Text>
                    </Stack>
                ))}
            </Stack>
        </Box>
    )
}

export default NearbyPlaces