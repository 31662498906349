


import React, { useMemo } from 'react'
import { Avatar, Box, Chip, IconButton, List, Sheet, Stack } from '@mui/joy'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Text from '../Text'
import moment from 'moment'
import { CheckRounded, MoreHorizRounded } from '@mui/icons-material'
import ActivityListItem from './ActivityListItem'
import SkuActivityActionModal from '../SKUActivityActionModal/ActionModal'
import { useAppContext } from '../../hooks/useAppContext'
import Table from '../Table'
import { useGetStoreSkuActivityQuery } from '../../store/query'
import ListFilterView from './ListFilterView'
import GalleryImage from '../GalleryView/GalleryImage'
import Link from '../Link'

const data = Array(80).fill(1).map(item => ({
  sr: "Alok Praakash",
  storeName: "D.K. Pharmacy",
  updatedDate: new Date(),
  status: "Incomplete"
}))


const columnHelper = createColumnHelper()

const getColumns = (appContext) => {
  const columns = [
    columnHelper.accessor('id', {
      header: <Text textAlign={"center"}>Sr. No.</Text>,
      cell: props => <Text textAlign={"center"}>{props.row.index + 1}</Text>,
      size: 48
    }),
    columnHelper.accessor('updatedAt', {
      header: "Time",
      cell: props => <Text>{moment(props.getValue()?.join("-")).format("DD MMMM YYYY")}</Text>,
      size: 80
    }),
    columnHelper.accessor('assignedUserName', {
      header: "Sales Representative",
      cell: props => (
        <Link to={`/employee/${props.row.original?.assignedUserIdentifier}`}>
          <Text>{props.getValue()}</Text>
        </Link>
      ),
      size: 120
    }),
    columnHelper.accessor('retailerName', {
      header: "Store Name",
      cell: props => (
        <Link to={`/store/${props.row.original?.retailerId}`}>
          <Text>{props.getValue()}</Text>
        </Link>
        )
    }),
    columnHelper.accessor('productCategoryName', {
      header: "Category",
      cell: props => <Text>{props.getValue()}</Text>,
      size: 100,
    }),
    // columnHelper.accessor('status', {
    //   header: "Status",
    //   cell: props => (
    //     <Chip variant='outlined' size='sm' color='warning' sx={{transform: "scale(0.8)"}}>
    //       <Text>{props.getValue() ?? "NEW"}</Text>
    //     </Chip>
    //   ),
    //   size: 64
    // }),
    columnHelper.accessor('images', {
      header: "Images",
      cell: props => (
        <Stack flexDirection={"row"} gap={1}>
          {
            props.getValue()?.map(item => (<GalleryImage src={item} style={{ width: "64px", aspectRatio: 1, borderRadius: 6 }} />))
          }
        </Stack>
      )
    }),
    // columnHelper.display({
    //   id: "actions",
    //   cell: props => {
    //     console.log("props", props.row);
    //     return (
    //       <Link to={"/store-sku-activity-action"} state={props.row.original} >
    //         <IconButton size="sm">
    //           <MoreHorizRounded fontSize="small" />
    //         </IconButton>
    //       </Link>
    //     )
    //   }
    // })
  ]

  return columns
}


const ActivityList = (props={
  showHeader : false
}) => {
  const app = useAppContext()
  const storeSkuActivity = useGetStoreSkuActivityQuery("")
  console.log("storeAct", storeSkuActivity.data);
  const columns = useMemo(() => getColumns(app), [])
  return (
    <Box pt={2}>
      <Table
        data={storeSkuActivity.data ?? []}
        loading={storeSkuActivity.isLoading}
        columns={columns}
        HeaderComponent={props.showHeader && ListFilterView}
        showFooter={props.showFooter}
      />
      {/* <Table hoverRow size='sm' stickyHeader>
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <th key={header.id}>
                        {header.isPlaceholder
                            ? null
                            : 
                            <Text fontSize={14} fontWeight={"600"} sx={{padding: "0 12px 0 12px"}}>
                                {
                                    flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )
                                }
                            </Text>
                            }
                        </th>
                    ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {
                    Array(20).fill(1)?.map((item, index) => <ActivityListItem item={item} index={index} />)
                }
            </tbody>
        </Table> */}
      <SkuActivityActionModal refs={app.skuActivityActionModalRef} />
    </Box>
  )
}

export default ActivityList