


import { Stack } from '@mui/joy'
import React from 'react'
import { BeatDetail } from '../../components/RetailBeat'
import Text from '../../components/Text'
import { PageHeader } from '../../components/Header'

const BeatDetailPage = () => {
  return (
    <Stack>
      <PageHeader />
        <Stack flexDirection={"row"} pb={2}>
            <Stack flex={1}>
                <Text level='h3'>Sona Beat</Text>
            </Stack>
            {/* <Button onClick={addNewBrand} startDecorator={<AddRounded fontSize='small' />}>Add Brand</Button> */}
        </Stack>
        <BeatDetail />
    </Stack>
  )
}

export default BeatDetailPage