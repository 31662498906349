


import { Box, Chip, CircularProgress, Stack, Tab, TabList, TabPanel, Tabs } from '@mui/joy'
import React, { useMemo } from 'react'
import Text from '../../components/Text'
import { PaymentMethods, StoreLoyaltyProgrammes, StoreTiming } from '../../components/StoreDetail'
import NearbyPlaces from '../../components/StoreDetail/NearbyPlaces'
import StoreSkuActivity from '../../components/StoreSkuActivity'
import StoreBrandByCategories from '../../components/StoreDetail/StoreBrandByCategories'
import { useGetStoreInfoQuery } from '../../store/query'
import { useParams } from 'react-router-dom'
import { StorefrontRounded } from '@mui/icons-material'

const StoreDetailPage = () => {
    const params = useParams()
    const retail = useGetStoreInfoQuery(params?.storeId)
    
    const address = useMemo(() => {
        let str = ""
        str += retail.data?.retailerAddress?.buildingName ? retail.data?.retailerAddress?.buildingName + ", " : ""
        str += retail.data?.retailerAddress?.city ? retail.data?.retailerAddress?.city + ", " : ""
        str += retail.data?.retailerAddress?.state ? retail.data?.retailerAddress?.state + ", " : ""
        str += retail.data?.retailerAddress?.country ? retail.data?.retailerAddress?.country + " " : ""
        return str
    }, [retail.data])
    if(retail.isLoading){
        return(
            <Stack justifyContent={"center"} alignItems={"center"} sx={{height: 480}}>
                <CircularProgress />
            </Stack>
        )
    }
    return (
        <Box padding={3}>
            <Stack flexDirection={"row"} pb={3}>
                <Box sx={{width: "200px"}}>
                    {
                        retail.data?.storeCompressedImages?.length > 0 ?
                        (<img src={retail.data?.storeCompressedImages[0]} alt="store-image" style={{width: "200px", aspectRatio: 1, borderRadius: 3}} />) :
                        (
                            <Box sx={{width: "200px", aspectRatio: 1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Box sx={{width: "120px", aspectRatio: 1, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 360}}>
                                    <StorefrontRounded sx={{fontSize: "56px", color: "rgba(0,0,0,0.7)"}} />
                                </Box>
                                <Text level='body-xs' pt={1}>Photo Not Added</Text>
                            </Box>
                        )
                    }
                </Box>
                <Box pl={2}>
                    <Text level='h3' pb={0.5}>{retail.data?.name}</Text>
                    <Stack flexDirection={"row"} gap={1} pb={2}>
                        <Chip variant='outlined'>{retail.data?.retailerType}</Chip>
                        {
                            retail.data?.isOpen ? <Chip variant='soft' color='success'>Open Now</Chip> : <Chip variant='soft' color='danger'>Closed Now</Chip>
                        }
                    </Stack>
                    <Text>
                        <Text level='body-sm'>Address :-</Text>
                        <Text level='title-sm'>{`  ${address}`}</Text>
                    </Text>
                    <Stack flexDirection={"row"} alignItems={'center'}>
                        <Text level='body-sm'>Contacts :- </Text>
                        <Box>
                            {
                                retail.data?.retailerContacts?.map(item => (
                                    <Text level='title-sm' pl={1}>{`${item.firstName} ${item.lastName} | ${item.mobileNumber}`}</Text>
                                ))
                            }
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            <Tabs orientation='vertical'>
                <TabList sx={{minWidth: 196}}>
                    <Tab>Timing</Tab>
                    <Tab>Loyalty Programmes</Tab>
                    <Tab>Nearby</Tab>
                    <Tab>Payment</Tab>
                    <Tab>Categories</Tab>
                    <Tab>Sku Activity</Tab>
                </TabList>
                <TabPanel value={0}>
                    <StoreTiming data={retail.data?.operatingTime} />
                </TabPanel>
                <TabPanel value={1}>
                    <StoreLoyaltyProgrammes data={retail.data?.loyalityProgrammeIds ?? []} />
                </TabPanel>
                <TabPanel value={2}>
                    <NearbyPlaces data={retail.data?.landmarkTypes ?? []} />
                </TabPanel>
                <TabPanel value={3}>
                    <PaymentMethods data={retail.data?.availablePaymentMethods ?? []} />
                </TabPanel>
                <TabPanel value={4}>
                    <StoreBrandByCategories data={retail.data?.productCategoryCompressedImages ?? []} />
                </TabPanel>
                <TabPanel value={5}>
                    <StoreSkuActivity />
                </TabPanel>
            </Tabs>
        </Box>
    )
}

export default StoreDetailPage