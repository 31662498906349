
import Papa from "papaparse"

export const getDelimitedCsv = (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            delimiter: ",",
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                if(results.errors.length > 0){
                    console.log("resdadad", results.errors);
                    reject(new Error("error parsing csv", results.errors))
                    return
                }
                const data = results.data;
                const csvString = Papa.unparse(data, { delimiter: "::" });
                const blob = new Blob([csvString], { type: "text/csv" });
                resolve(blob)
            }
        })
    })
}