


import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGoogleLogin } from '@react-oauth/google';
import { sleep } from '../utils'
import { loginUser } from '../store/actions/authAction'
import axios from 'axios';
import Axios from '../utils/axios';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import config from '../config';

export const useAuthentication = () => {

    const [status, setStatus] = useState(0)
    const [authenticating, setAuthenticating] = useState(false)

    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [otp, setOtp] = useState("")
    const [otpError, setOtpError] = useState("")

    const onSuccess = (token) => {
        loginUser(token)
    }

    const googleSignIn = useGoogleLogin({
        onSuccess : onSuccess
    })
    const auth = useSelector(store => store.auth)

    const handleGmailLogin = useCallback(async() => {
        try {
            setAuthenticating(true)
            await sleep(1000)
            googleSignIn()
        } catch (error) {
            console.log("handleGmailLogin", error)
        }finally{
            setAuthenticating(false)
        }
    }, [])

    const validatePhone = () => {
        if(phone.length == 10) return true
        if(phone.length < 10){
            setOtpError("Phone number will be of 10 character")
        }
    }

    const requestOtp = async() => {
        try {
            if(!validatePhone()) return
            const response = await Axios.post("/sendOtp", {
                "communicationId": phone,
                "country_code" : "+91",
                "channel": "sms"
            })
            setStatus(1)
            toast("OTP Sent.")
        } catch (error) {
            if(error?.response){
                toast(error.response?.data?.message, {style : config.toastOptions.error.style})
            }
            console.log("adad", error);
        }
    }

    const verifyOtp = async() => {
        try {
            const response = await Axios.post("/verifyOtp", {
                "communicationId": phone,
                "country_code" : "+91",
                "channel": "sms",
                "otp" : otp
            })
            if(response.data?.error){
                toast(response?.data?.error?.message, {style : config.toastOptions.error.style})
                return
            }
            onSuccess(response.data.token)
        } catch (error) {
            
        }
    }

    return {
        status,
        phone,
        setPhone,
        otp,
        setOtp,
        authenticating,
        isLogin : auth.isLogin,
        loginWithGoogle : handleGmailLogin,
        requestOtp,
        verifyOtp
    }
}