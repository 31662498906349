

import { ChevronLeft, ChevronRightRounded } from '@mui/icons-material'
import { Button, Stack } from '@mui/joy'
import React, { useMemo } from 'react'

const PaginatedFooter = (props) => {
  const hasNextPage = useMemo(() => props.filter.currentPage < props.filter.totalPage - 1, [props.filter.totalPage, props.filter.currentPage])
  const hasPreviousPage = useMemo(() => props.filter.currentPage > 0, [props.filter.totalPage, props.filter.currentPage])
  const getNextPage = () => {
    props.handleFilterChange({currentPage: Math.min(props.filter.totalPage, props.filter.currentPage + 1)})
  }
  const getPreviousPage = () => {
    props.handleFilterChange({currentPage: Math.max(0, props.filter.currentPage - 1)})
  }
  const buttons = useMemo(() => {
    const totalPage = props.filter.totalPage
    const page = [1]
    for(let i= props.filter.currentPage - 1; i <= totalPage; i++){
      if(page.length <= 3){
        const index = Math.max(i, 1)
        if(!page.includes(index)) page.push(index)
      }
    }
    if(!page.includes(props.filter.totalPage)) page.push(props.filter.totalPage)
    return page
  }, [props.filter.totalPage, props.filter.currentPage])

  return (
    <Stack flexDirection={"row"} justifyContent={"flex-end"} sx={{ py: 2 }}>
      <Button
        variant='outlined'
        size='sm'
        color='neutral'
        disabled={!hasPreviousPage}
        onClick={getPreviousPage}
        startDecorator={<ChevronLeft />}
        sx={{ transform: "scale(0.85)" }}>Previous</Button>
      {
        buttons.map(page => <Button onClick={() => props.handleFilterChange({ currentPage: page - 1 })} variant={props.filter.currentPage == page -1 ? "solid" : 'outlined'} color={props.filter.currentPage == page - 1 ? "primary" : 'neutral'} sx={{ transform: "scale(0.85)" }}>{page}</Button>)
      }
      <Button variant='outlined' disabled={!hasNextPage} onClick={getNextPage} color='neutral' endDecorator={<ChevronRightRounded />} sx={{ transform: "scale(0.85)" }}>Next</Button>
    </Stack>
  )
}

export default PaginatedFooter