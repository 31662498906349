



import React from 'react'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';
import Store, { persistor } from './store'
import Router from './routes'
import AppContextProvider from './AppContextProvider';
import { CssVarsProvider as JoyThemeProvider } from '@mui/joy';
import JoyAppTheme from './config/theme';
import { Toaster } from 'react-hot-toast';
import { PersistGate } from 'redux-persist/integration/react';
import ServerErrorPage from './pages/serverError';
import Error404 from './pages/error404';
import config from './config';

const clientId = process.env.REACT_APP_FIREBASE_CLIENT_ID

const App = () => {
  return (
    <Provider store={Store}>
      {/* <JoyThemeProvider theme={JoyAppTheme}> */}
      <PersistGate loading={null} persistor={persistor}>
        <AppContextProvider>
          <GoogleOAuthProvider clientId={clientId}>
            <Router />
            <Toaster toastOptions={config.toastOptions} />
          </GoogleOAuthProvider>
        </AppContextProvider>
      </PersistGate>
      {/* </JoyThemeProvider> */}
    </Provider>
  )
}

export default App