
export const actions = {
    mangeOwnProfile : "manage-own-profile",
    manageUser : "manage-user",
    manageBrand : "manage-brand",
    bulkUpload : "bulk-upload-file",
    manageRetailStore : "manage-retail-store",
    viewSalesPersonActivity : "view-sales-person-activity",
    viewDashboard : "view-dashboard",
    updateImageLabel : "update-image-label"
}

export const defaultActions = [
    actions.mangeOwnProfile
]


export const access = {
    "ADMIN" : Object.values(actions),
    "DEFAULT" : [
        ...defaultActions
    ],
    dataLabel : [
        ...defaultActions,
        actions.updateImageLabel
    ]
}