


import React from 'react'
import StoreListView from '../../components/StoreList/StoreListView'
import { Box, Button, Stack } from '@mui/joy'
import StoreListFilter from '../../components/StoreList/StoreListFilter'
import Text from '../../components/Text'
import { AddRounded } from '@mui/icons-material'
import RetailList from '../../components/StoreList'
import { StoreList, StoreUtil } from '../../components/Store'

const RetailStorePage = () => {
  return (
    <Stack flex={1} sx={{padding: 3}}>
        <Stack flexDirection={"row"} alignItems={"center"} pb={2}>
            <Text level='h2' sx={{flex: 1}}>Store List</Text>
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Button onClick={StoreUtil.exportOutletWithBrandAndQuantityToCsv}>Download Outlet with Brand</Button>
              {/* <Button startDecorator={<AddRounded />}>Add Shop</Button> */}
            </Stack>
        </Stack>
        {/* <RetailList /> */}
        <StoreList />
    </Stack>
  )
}

export default RetailStorePage