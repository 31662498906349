


import { NotificationsRounded, SearchRounded } from '@mui/icons-material'
import { IconButton, Sheet, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'
import Modalize from '../Modalize'
import SearchView from './SearchView'
import { useAppContext } from '../../hooks/useAppContext'
  
const SearchBar = () => {
    const app = useAppContext()
    const onSearchClick = () => {
        console.log("HelloSearch", app.searchModalRef.current);
        app.searchModalRef.current?.open(true)
    }
    return (
        <Sheet variant='solid' color='primary' sx={{width: "100%" }}>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ paddingInline: 3, height: 64 }}>
                <Stack onClick={onSearchClick} flexDirection="row" alignItems="center" sx={{ backgroundColor: "rgba(255,255,255,0.8)", borderRadius: "16px", minWidth: 360, height: 42, cursor: "pointer" }}>
                    <Stack px={1}>
                        <SearchRounded color='primary' />
                    </Stack>
                    <Text>Search</Text>
                </Stack>
                <Stack flex={1} />
                {/* <IconButton color='primary'>
                    <NotificationsRounded />
                </IconButton>
                <IconButton sx={{ color: "#fff" }}>
                    <NotificationsRounded />
                </IconButton>
                <IconButton sx={{ color: "#fff" }}>
                    <NotificationsRounded />
                </IconButton> */}
            </Stack>
        </Sheet>
    )
}

export const SearchModal = ({ refs }) => (
    <Modalize
        ref={refs}
        Component={() => <SearchView containerStyle={{ width: "90%", maxWidth: 900, maxHeight: 600, borderRadius: 'md', p: 3 }} />}
    />
)

export default SearchBar