


import { Autocomplete, Avatar, Box, Button, IconButton, Modal, Option, Select, Sheet, Stack } from '@mui/joy'
import React, { useImperativeHandle, useState } from 'react'
import Text from '../Text'
import { CloseRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import { useGetUserRoleQuery, useUpdateUserRoleMutation } from '../../store/query'

const EditUserRoleView = (props, ref) => {
    const userRole = useGetUserRoleQuery()
    const [updateRole, mutation] = useUpdateUserRoleMutation()
    const {data, close} = props
    const [roles, setRole] = useState([])
    const onSubmit = async() => {
        if(roles.length > 0){
            await updateRole({
                email : data.email,
                role : roles[0]
            })
            close()
        }
    }
  return (
        <Sheet sx={{width: "480px", padding: 3, borderRadius: 6}}>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} pb={3}>
                <Text level='h4'>Edit User Role</Text>
                <IconButton onClick={close}>
                    <CloseRounded />
                </IconButton>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} pb={3}>
                <Avatar alt={data?.displayName} />
                <Box pl={1.3}>
                    <Text level='title-md'>{data?.displayName}</Text>
                    <Text level='body-sm'>{data?.email}</Text>
                </Box>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} pb={3}>
                <Text level='title-sm'>Select Role</Text>
                <Autocomplete
                    multiple
                    getOptionLabel={option => option}
                    size='sm' 
                    defaultValue={data?.userRole}
                    onChange={(e, data) => setRole(data)}
                    options={userRole.data ?? []}
                    sx={{maxWidth: "300px", width: "100%"}} />
            </Stack>
            <Box sx={{textAlign: "center"}}>
                <Button 
                    size='sm'
                    loading={mutation.isLoading}
                    onClick={onSubmit}>Submit</Button>
            </Box>
        </Sheet>
  )
}

export default EditUserRoleView