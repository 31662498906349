import toast from "react-hot-toast"
import Store from ".."
import { ActionType } from "../reducers/authReducer"
import config from "../../config"




export const loginUser = (token) => {
    try {
        Store.dispatch({
            type: ActionType.LOGIN_USER,
            payload : {
                isLogin : true,
                token : token,
                lastTokenRefreshed : Date.now(),
                role : null
            }
        })
        toast("Login Success.", {style : config.toastOptions.success.style})
    } catch (error) {
        
    }
}


export const logoutUser = () => {
    try {
        Store.dispatch({
            type: ActionType.LOGOUT_USER
        })
    } catch (error) {
        
    }
}