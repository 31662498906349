


import { Box, Drawer, Stack } from '@mui/joy'
import React, { useMemo } from 'react'
import DrawerContent from './DrawerContent'

const NavigationBar = () => {
    return(
        <Stack
            sx={{
                width: 220,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                width: 220,
                boxSizing: 'border-box',
                }}}>
            <DrawerContent />
        </Stack>
    )
}

export default NavigationBar