


import { Fade, Menu, MenuItem } from '@mui/material'
import React, { useImperativeHandle, useState } from 'react'
import renderMenuFromData from './renderMenu'

const ContextMenu = React.forwardRef((props, ref) => {
    const [state, setState] = useState(null)

    const onClose = () => {
        setState(null)
    }

    useImperativeHandle(ref, () => {
        return {
            open : (data={
                data : null,
                top: 0,
                left : 0,
            }) => {
                setState(data)
            },
            close : onClose
        }
    })

    return (
        <Menu
            open={state ? true : false}
            onClose={onClose}
            anchorReference='anchorPosition'
            slotProps={{root : {overflowX: "auto"}}}
            anchorPosition={state ? { top: state.top, left: state.left } : undefined}
        >
            {
                state?.data ? state.data : null
            }
        </Menu>
    )
})

export const renderMenu = renderMenuFromData
export default ContextMenu