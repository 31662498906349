

import { Box, FormControl, FormLabel, Input, Modal } from '@mui/joy'
import React, { useImperativeHandle, useState } from 'react'
import AddUserView from './AddUserView'

const AddUserModal = React.forwardRef((props, ref) => {

    const [visible, setVisible] = useState(false)

    const open = () => {
        console.log("Hello");
        setVisible(true)
    }

    const close = () => {
        setVisible(false)
    }

    useImperativeHandle(ref, () => {
        return {
            open
        }
    })

  return (
    <Modal
        open={visible}
        onClose={close}
        sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <AddUserView onSuccess={close} />
    </Modal>
  )
})

export default AddUserModal