
import axios from "axios"
import Store from "../store";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

Axios.interceptors.request.use(function (config) {
  config.headers["accept"] = "application/json";
  config.headers["X-FDM-Request-Id"] = Date.now()
  config.headers["X-Auth-Token"] = Store.getState().auth.token
  return config;
}, function (error) {
  return Promise.reject(error);
});



Axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


export default Axios