


import { Box, Stack } from '@mui/joy'
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import React, { useMemo } from 'react'
import Text from '../Text'
import { useGetShopPaymentMethodQuery } from '../../store/query'

const PaymentMethods = ({data}) => {
    const paymentMethod = useGetShopPaymentMethodQuery()
    const list = useMemo(() => {
        if(data.length > 0){
            return paymentMethod.data?.filter(item => {
                return data.includes(item.id)
            }) ?? []
        }
        return []
    }, [paymentMethod.data])
    console.log("paymentMethod", paymentMethod);
    return (
        <Box>
            {
                list.length == 0 && (
                    <Box>
                        <Text>Payment Method not added</Text>
                    </Box>
                )
            }
            <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} sx={{ width: "100%", height: 450 }} cols={12} rowHeight={64}>
                {list.map((item) => (
                    <Stack sx={{width: 80}}>
                        <Stack sx={{width: "100%", aspectRatio: 1}}>
                            <img src={item.image} alt={item.id} style={{objectFit:"contain", width: "100%", height: "100%"}} />
                        </Stack>
                        <Text level='body-sm' sx={{textAlign: "center", wordWrap: "break-word"}}>{item?.id}</Text>
                    </Stack>
                ))}
            </Stack>
        </Box>
    )
}

export default PaymentMethods