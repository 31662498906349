


import { Autocomplete, Avatar, Box, Button, Grid, Modal, Sheet, Stack } from '@mui/joy'
import React, { useImperativeHandle, useState } from 'react'
import Text from '../Text'
import ActivityActionView from './ActivityActionView'
import Modalize from '../Modalize'

const ActionModal = ({refs}) => (
    <Modalize 
      ref={refs} 
      Component={<ActivityActionView containerStyle={{ width: "90%", maxWidth: 900, maxHeight: 600, borderRadius: 'md', p: 0 }} />} 
      />
    )

export default ActionModal