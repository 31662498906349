


import React from 'react'
import { useAppContext } from '../../hooks/useAppContext'

const GalleryImage = (props) => {
    const app = useAppContext()
    const handleClick = () => {
        app.galleryRef?.current?.open([props.src])
    }
  return (
    <img {...props} alt={props.src} onClick={handleClick} />
  )
}

export default GalleryImage