


import { DataArrayRounded, DocumentScannerRounded } from '@mui/icons-material'
import { CircularProgress, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'

const EmptyComponent = (props={
  loading: true
}) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} sx={{height: 400}}>
      {
        props.loading ? 
          <CircularProgress /> :
          <>
            <DocumentScannerRounded sx={{fontSize: "72px", color: "gray"}} />
            <Text level='title-sm' color='warning' pt={2}>No data found.</Text>
          </>
      }
        
    </Stack>
  )
}

export default EmptyComponent