
const config = {}

config.toastOptions = {
    position: "top-right", 
    duration: 5000,
    style : {
        backgroundColor : "#747474",
        color : "#fff"
    },
    loading : {
        duration : Infinity
    },
    error : {
        style: {
            backgroundColor: "#ea001e", 
            color: "#fff"
        },
        duration : 8000
    },
    success: {
        style: {
            backgroundColor: "#2e844a", 
            color: "#fff"
        }
    }
}


export default config