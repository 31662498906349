

import React, { useMemo, useRef } from 'react'
import { createTaskWorkerInstance } from './worker'

export const AppContext = React.createContext(null)

const AppContextProvider = (props) => {

  const searchModalRef = useRef()
  const contextMenuRef = useRef()
  const galleryRef = useRef()
  const addUserModalRef = useRef()
  const taskWorker = useMemo(createTaskWorkerInstance, [])
  const editUserProfileModalRef = useRef()
  const skuActivityActionModalRef = useRef()
  const editUserRoleModalRef = useRef()
  const addBrandModalRef = useRef()
  
  const value = {
    skuActivityActionModalRef,
    contextMenuRef,
    taskWorker,
    editUserRoleModalRef,
    editUserProfileModalRef,
    addUserModalRef,
    galleryRef,
    addBrandModalRef,
    searchModalRef
  }

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider