

import { Modal } from '@mui/joy'
// import { Modal } from '@mui/material'
import React, { useImperativeHandle, useState } from 'react'
import Gallery from './Gallery'

const GalleryModalView = React.forwardRef((props, ref) => {

    const [state, setState] = useState(null)

    const open = (data) => {
        setState(data)
    }

    const close = () => {
        setState(null)
    }

    useImperativeHandle(ref, () => {
        return {
            open
        }
    })

  return (
    <Modal 
        open={state ? true : false}
        onClose={close}
        >
        <Gallery data={state} onClose={close} />
    </Modal>
  )
})

export default GalleryModalView