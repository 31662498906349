

import { Stack, Step, StepIndicator, Stepper } from '@mui/joy'
import React from 'react'
import Text from '../Text'
import { useGetOrderDetailQuery } from '../../store/query'
import { useParams } from 'react-router-dom'
import ProductListView from './ProductListView'
import { CheckRounded, CloseRounded } from '@mui/icons-material'
import Link from '../Link'

const Detail = (props = {
    orderId: 0
}) => {

    const order = useGetOrderDetailQuery(props.orderId)

    if (!order.data) return null

    return (
        <Stack>
            <Stack m={3}>
                <Stepper sx={{ width: 600 }}>
                    <StepperStep label='Placed' step={1} completed={1} />
                    <StepperStep label='Accepted' step={2} />
                    <StepperStep label='Fulfilled' step={3} />
                    <StepperStep label='Dispatched' step={4} />
                    <StepperStep label='Delivered' step={5} />
                    <StepperStep label='Settled' step={5} />
                </Stepper>
            </Stack>
            <Stack>
                <Text level='title-lg'>#OD100000232</Text>
            </Stack>
            <Stack flexDirection={"row"} flexWrap={"wrap"} maxWidth={800}>
                <Stack sx={{ minWidth: 360 }}>
                    <RowStack>
                        <Text level='title-sm'>Order Amount</Text>
                        <Text level='title-sm'>{order.data?.amount}</Text>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>Transaction ID</Text>
                        <Text level='title-sm'>#{order.data?.transactionId}</Text>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>Pjpoutlet</Text>
                        <Text level='title-sm'>{order.data?.pjpoutlet}</Text>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>Amount</Text>
                        <Text level='title-sm'>{order.data?.amount}</Text>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>Outlet</Text>
                        <Stack sx={{maxWidth: 240}}>
                            <Link to={`/store/${order.data?.outlet?.id}`}>
                                <Text level='title-sm' textAlign={"right"}>{order.data?.outlet?.name}</Text>
                            </Link>
                            <Text level='body-xs' textAlign={"right"} pl={2}>{order.data?.outlet?.retailerAddress?.buildingName}, {order.data?.outlet?.retailerAddress?.streetName}</Text>
                            <Text level='body-xs' textAlign={"right"}>{order.data?.outlet?.retailerAddress?.landmark}</Text>
                            <Text level='body-xs' textAlign={"right"}>{order.data?.outlet?.retailerAddress?.pincode}, {order.data?.outlet?.retailerAddress?.city}, {order.data?.outlet?.retailerAddress?.state}</Text>
                        </Stack>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>Beat</Text>
                        <Text level='title-sm'>{order.data?.user}</Text>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>User</Text>
                        <Text level='title-sm'>{order.data?.user}</Text>
                    </RowStack>
                    <RowStack>
                        <Text level='title-sm'>Comment</Text>
                        <Text level='title-sm'>{order.data?.comment}</Text>
                    </RowStack>
                </Stack>
            </Stack>
            <Stack pt={3}>
                {/* <ProductListView /> */}
            </Stack>
        </Stack>
    )
}

const RowStack = (props) => {
    return (
        <Stack flex={1} flexDirection={"row"} justifyContent={"space-between"} py={1.4}>
            {props.children}
        </Stack>
    )
}

const StepperStep = (props={
    label : "",
    completed : 0,
    step : 0
}) => {
    const Indicator = () => {
        switch(props.completed){
            case 1 : return (
                <StepIndicator variant="solid" color='success'>
                    <CheckRounded fontSize='small' />
                </StepIndicator>
            )
            case 0 : return(
                <StepIndicator variant="solid" color='danger'>
                    <CloseRounded fontSize='small' />
                </StepIndicator>
            )
            default : return (
                <StepIndicator variant="solid">
                    {props.step}
                </StepIndicator>
            )
        }
    }
    return(
        <Step
            orientation="vertical"
            indicator={
            <StepIndicator variant="outlined">
                <Indicator />
            </StepIndicator>
        } >
            {props.label}
        </Step>
    )
}

export default Detail