


import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetBrandInfoQuery, useGetProductCategoryQuery, useUpdateBrandMutation } from '../store/query'

const useBrandForm = (props) => {
    const {data} = props
    const brandId = data?.id
    const productCategory = useGetProductCategoryQuery()
    // const brand = useGetBrandInfoQuery(brandId)
    const brand = {data}
    const [update] = useUpdateBrandMutation()

    const form = useForm({
        values : {
            "id": brandId,
            "name": brand.data?.name ?? "",
            "logo": "",
            popular : false,
            category : []
        }
    })

    const submit = form.handleSubmit(async(data) => {
        data.productCategoryIds = data.category?.map(item => item.id) ?? []
        await update(data)
        props.onSubmit()
    })

    return {
        ...form,
        brand : brand.data,
        productCategory,
        submit
    }
}

export default useBrandForm