


import { Modal } from '@mui/joy'
import React, { useImperativeHandle, useRef, useState } from 'react'

const Modalize = React.forwardRef(({ Component }, ref) => {
    const [state, setState] = useState(null)
    const promise = useRef()
    const open = (data) => {
        setState(data)
        return new Promise((resolve) => {
            promise.current = resolve
        })
    }
    const close = () => {
        if(promise.current){
            promise.current(true)
        }
        setState(null)
    }
    useImperativeHandle(ref, () => {
        return {
            open,
            close
        }
    })
    return (
        <Modal
            open={state ? true : false}
            onClose={close}
            sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
        >
            <Component data={state} close={close} />
        </Modal>
    )
})

export default Modalize