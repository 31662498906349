

export const ActionType = {
    updateTableFilter : "table/update-filter"
}

export const tableReducer = (state={}, action) => {
    switch(action.type){
        case ActionType.updateTableFilter : return {
            ...state,
            ...action.payload
        }
        default : return state
    }
}