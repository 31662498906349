


import { Stack } from '@mui/joy'
import React from 'react'
import Text from '../../components/Text'
import { WarehouseList } from '../../components/Warehouse'

const WarehousePage = () => {
  return (
    <Stack p={3}>
        <Text level="h3" pb={2.5}>Warehouses</Text>
        <WarehouseList />
    </Stack>
  )
}

export default WarehousePage