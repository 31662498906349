


import ImageZoom from 'js-image-zoom';
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { sleep } from '../../utils';

const ZoomImage = (props={
    key : 0,
    position : "",
    offset : {}
}) => {
    const ref = useRef()
    const setupZoom = async() => {
        // await sleep(1000)
        const option = {
            width : props.style.width,
            zoomWidth: props.style.width,
            fillContainer: true,
            zoomPosition: props.position ?? "right",
            offset: props.offset ?? {vertical : 0, horizontal : 10}
        }
        new ImageZoom(ref.current, option)
    }
    useLayoutEffect(() => {
        setupZoom()
    }, [])
    
    return(
        <div ref={ref} style={{zIndex: 1, height: 300}}>
            <img {...props} alt={props.src} />
        </div>
    )
}

export default ZoomImage
