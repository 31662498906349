


import { Box, Link as JoyLink } from '@mui/joy'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Link = React.memo((props={
    to : "",
    state : {}
}) => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate(props.to, {state: props.state})
    }
  return (
    <JoyLink {...props} onClick={onClick}>
        {props.children}
    </JoyLink>
  )
})

export default Link