


import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetUserInfoQuery, useUpdateProfileMutation } from '../store/query'
import toast from 'react-hot-toast'
import config from '../config'

const useUserProfileForm = (props={
	userId : 0
}) => {

	const profile = useGetUserInfoQuery("")

	// const [profile, setProfile] = useState( {
	// 	data : {
	// 		"firstName": "Alok Prakash",
	// 		"lastName": null,
	// 		"displayName": "Alok Prakash",
	// 		"countryCode": "+91",
	// 		"phoneNumber": "9340655701",
	// 		"email": "alok@fairdeal.market",
	// 		"identifier": null,
	// 		"aliasToAddressMap": {},
	// 		"badges": null,
	// 		"profilePicS3Url": null,
	// 		"token": null,
	// 		"userRole": []
	// 	}
	// })

	const [updateProfile] = useUpdateProfileMutation()

	const form = useForm({
		values: {
			"firstName": profile.data?.firstName,
			"lastName": profile.data?.lastName,
			"photo" : profile.data?.profilePicS3Url ?? "",
			"email" : profile.data?.email ?? "",
			"phoneNumber" : profile.data?.phoneNumber ?? "",
			"displayName": profile.data?.firstName?.split(" ")?.[0] ?? "",
			"address" : Object.keys(profile.data?.aliasToAddressMap ?? {})?.length > 0 ? Object.keys(profile.data?.aliasToAddressMap)?.map(item => {
				let data = {
					...profile.data?.aliasToAddressMap?.[item],
					label : item,
					country : "India"
				}
				return data
			}) : [{label: "Current Address", country : "India"}, {label: "Permanent Address", country : "India"}]
		}
	})

	const onSubmit = form.handleSubmit(async() => {
		const data = form.getValues()
		console.log("data", data, form.getValues());
		data.address?.forEach(item => {
			data.aliasToAddressMap = {
				[item.label] : item
			}
		})
		delete data.address
		toast.promise(updateProfile(data), 
		{
			loading : "Saving",
			success : "Saved",
			error : (error) =>  "Not Saved  " + error.message
		})
		// setProfile(data)
	})

	return {
		...form,
		profile,
		onSubmit
	}
}

export default useUserProfileForm