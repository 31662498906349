


import { Box, Stack } from '@mui/joy'
import React, { useMemo } from 'react'
import Text from '../Text'
import moment from 'moment'
import { parseStoreOperatingTime } from '../../utils'


const TimingList = [
    { title: 'Monday', time: '--:-- AM to --:-- PM' },
    { title: 'Tuesday', time: '--:-- AM to --:-- PM' },
    { title: 'Wednesday', time: '--:-- AM to --:-- PM' },
    { title: 'Thursday', time: '--:-- AM to --:-- PM' },
    { title: 'Friday', time: '--:-- AM to --:-- PM' },
    { title: 'Saturday', time: '--:-- AM to --:-- PM' },
    { title: 'Sunday', time: '--:-- AM to --:-- PM' },
  ]

const StoreTiming = ({data}) => {

    const shopTime = useMemo(() => {
        const time = data
        return TimingList?.map(item => {
          const day = time?.find(e => e.day == item.title)
          if (day) {
            if (day.startTime == 0 || day.endTime == 0) {
              return { ...item, time: "Holiday", isHoliday : true }
            }
            return {
              ...item,
              startTime : moment(parseStoreOperatingTime(day.startTime)).format("HH:mm A"),
              endTime: moment(parseStoreOperatingTime(day.endTime)).format("HH:mm A"),
              isHoliday : day.startTime == 0 || day.endTime == 0
            }
          }
          return item
        })
      }, [data])

  return (
    <Box>
        {
            shopTime.map((item, index) => (
                <Stack key={item.title} flexDirection={"row"} pb={1.6}>
                    <Box sx={{flex: 1}}>
                        <Text level='body-md'>{item.title}</Text>
                    </Box>
                    <Box>
                        {
                            item.isHoliday ?
                            (
                                <Text level='title-sm' color='danger'>Holiday</Text>
                            ) : (
                                item.startTime ?
                                <Text>
                                    <Text level='title-sm'>{item.startTime}</Text>
                                    <Text>{" - "}</Text>
                                    <Text level='title-sm'>{item.endTime}</Text>
                                </Text> :
                                <Text level='title-sm'>Not Set</Text>
                            )
                        }
                    </Box>
                </Stack>
            ))
        }
    </Box>
  )
}

export default StoreTiming