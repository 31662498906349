


import React, { useEffect, useRef } from 'react'
import Text from '../../components/Text'
import { Box, Button, FormControl, Link, List, ListItem, ListItemButton, Stack } from '@mui/joy'
import Table from '../../components/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { DownloadRounded, UploadRounded } from '@mui/icons-material'
import { useAppContext } from '../../hooks/useAppContext'
import toast from 'react-hot-toast'
import { uploadUserListInBulk } from '../../utils/bulk-upload'
import { query } from '../../store/query'
import { dispatch } from '../../store'
import Axios from '../../utils/axios'
import { getDelimitedCsv } from '../../utils/csv-delimiter'
import { sleep } from '../../utils'


const uploadUserList = async(event) => {
    try {
        const task = async() => {
            const file = await getDelimitedCsv(event.target.files[0])
            const formdata = new FormData()
            formdata.append("usersFileCsv", file)
            const response = await dispatch(query.endpoints.uploadUserListFile.initiate(formdata))
            event.target.value = null
            if(response.error){
                throw Error("Uploaded file do not match with sample format.")
            }
            // const csv = window.URL.createObjectURL(file)
            // window.open(csv, "_blank")
        }
        toast.promise(
            task(),
            {
                loading : "Uploading UserList",
                success : "Uploaded Successfully",
                error : (error) =>  "User Bulk Upload Failed : " + error.message
            }
        )
    } catch (error) {
        console.log("uploadUserListFile", error);
    }
}

const uploadRetailer = (event) => {
    try {
        const task = async() => {
            const file = await getDelimitedCsv(event.target.files[0])
            const formdata = new FormData()
            formdata.append("retailersFileCsv", file)
            const response = await dispatch(query.endpoints.uploadRetailerListFile.initiate(formdata))
            event.target.value = null
            if(response.error){
                throw Error("Uploaded file do not match with sample format.")
            }
            // const csv = window.URL.createObjectURL(file)
            // window.open(csv, "_blank")
        }
        toast.promise(
            task(),
            {
                loading : "Uploading Retailer File",
                success : "Uploaded Successfully",
                error : (error) =>  "Retailer Bulk Upload Failed : " + error.message
            }
        )
    } catch (error) {
        console.log("uploadUserListFile", error);
    }
}

const uploadUserBeatList = async(event) => {
    try {
        const task = async() => {
            const file = await getDelimitedCsv(event.target.files[0])
            const formdata = new FormData()
            formdata.append("beatFileCsv", file)
            const response = await dispatch(query.endpoints.uploadUserBeatMappingFile.initiate(formdata))
            event.target.value = null
            if(response.error){
                throw Error("Uploaded file do not match with sample format.")
            }
        }
        toast.promise(
            task(),
            {
                loading : "Uploading UserList",
                success : "Uploaded Successfully",
                error : (error) =>  "User Beat Mapping Bulk Upload Failed : " + error.message
            }
        )
    } catch (error) {
        console.log("uploadUserListFile", error);
    }
}

const uploadSkuList = async(event) => {
    try {
        const task = async() => {
            const file = await getDelimitedCsv(event.target.files[0])
            // const formdata = new FormData()
            // formdata.append("beatFileCsv", file)
            // const response = await dispatch(query.endpoints.uploadUserBeatMappingFile.initiate(formdata))
            // event.target.value = null
            // if(response.error){
            //     throw Error("Uploaded file do not match with sample format.")
            // }
            const csv = window.URL.createObjectURL(file)
            window.open(csv, "_blank")
        }
        toast.promise(
            task(),
            {
                loading : "Uploading UserList",
                success : "Uploaded Successfully",
                error : (error) =>  "User Beat Mapping Bulk Upload Failed : " + error.message
            }
        )
    } catch (error) {
        console.log("uploadUserListFile", error);
    }
}

const data = [
    {
        textInputId : "userListInput",
        title : "Upload User List",
        sampleFileUri : require("../../assets/docs/sample-user-list.csv"),
        sampleFileName : "sample-user-bulk-upload-file.csv",
        handleUpload : uploadUserList
    },
    {
        textInputId : "uploadRetailer",
        title : "Upload Retailer List",
        sampleFileUri : require("../../assets/docs/sample-retailer-file.csv"),
        sampleFileName : "sample-retailer-bulk-upload-file.csv",
        handleUpload : uploadRetailer
    },
    {
        textInputId : "beatAssignedInput",
        title : "Upload Beat Assigned",
        sampleFileUri : require("../../assets/docs/sample-user-beat-mapping.csv"),
        sampleFileName : "sample-user-beat-mapping-bulk-upload.csv",
        handleUpload : uploadUserBeatList
    },
    {
        textInputId : "skuAssignedInput",
        title : "Upload SKU Assigned",
        sampleFileUri : require("../../assets/docs/sample-user-beat-mapping.csv"),
        sampleFileName : "sample-sku-mapping-bulk-upload.csv",
        handleUpload : uploadSkuList
    },
]

const columnHelper = createColumnHelper()

const columns = [
    columnHelper.accessor("title", {
        header : "Bulk Actions",
        cell : props => {
            return(
                <Text>{props.getValue()}</Text>
            )
        },
    }),
    columnHelper.accessor("sampleDownload", {
        header : "Download Sample Format",
        cell : props => {
            return(
                <Link href={props.row.original.sampleFileUri} download={props.row.original.sampleFileName}>
                    <Button variant='plain' size='sm' startDecorator={<DownloadRounded fontSize='18px' />} >
                        <Text level='body-xs' color='primary'>Download Sample Format</Text>
                    </Button>
                </Link>
            )
        }
    }),
    columnHelper.accessor("textInputId", {
        header : "ActionList",
        cell : props => {
            const openFileInputWindow = () => {
                document.getElementById(props.getValue()).click()
            }
            return(
                <Button onClick={openFileInputWindow} variant='soft' size='sm' startDecorator={<UploadRounded fontSize='18px' />} >
                    <Text level='body-xs' color='primary'>Upload File</Text>
                        <input 
                            id={props.getValue()} 
                            type='file' 
                            style={{width: 0}}     
                            // accept='text/csv'
                            onChange={props.row.original.handleUpload}  />
                </Button>
            )
        }
    })
]


const BulkUploadPage = () => {
  return (
    <Box px={3} py={2}>
        <Text level='h2' mb={2}>Bulk Upload</Text>
        <Table
            data={data}
            columns={columns}
            showFooter={false} />
    </Box>
  )
}

export default BulkUploadPage