import moment from "moment"



export const sleep = (time = 1000) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(true)
        }, time)
    })
}

export const parseStoreOperatingTime = (time) => {
    if (time >= 0) {
      const hours = Math.floor(time / 100)
      const minutes = time % 100
      return moment().set({ hours: hours, minutes: minutes }).format()
    }
}

export const isShopOpen = (operatingTime) => {
  const currentDay = moment().format("dddd")
  const currentTime = moment().format("HHmm")
  if (operatingTime) {
    const day = operatingTime?.find(item => item.day == currentDay)
    if (currentTime >= day?.startTime && currentTime <= day?.endTime) {
      return true
    }
  }
  return false
}

export const parseTimeArray = (time, defaultTime, object) => {
  if(time){
    const t = moment(`${time[0]}/${time[1]}/${time[2]}`)
    if(object){
      return t
    }
    return t.format()
  }
  return time
}