import { createColumnHelper } from "@tanstack/react-table";
import { useGetUserListQuery } from "../../store/query";
import Table from "../Table";
import UserList from "./ListView";
import Text from "../Text";
import { Avatar, Chip, IconButton, Stack } from "@mui/joy";
import { MoreHorizRounded } from "@mui/icons-material";
import { useAppContext } from "../../hooks/useAppContext";
import ListFilterView from "./ListFilterView";
import { renderMenu } from "../ContextMenu";


const columnHelper = createColumnHelper()


const MoreButton = () => {
    const app = useAppContext()
    const onContextMenu = (event) => {
        event.preventDefault()
        app.contextMenuRef?.current?.open({
            data : null,
            top: event.clientY - 6,
            left : event.clientX + 2,
        })
    }
    return(
        <IconButton size="sm" onClick={onContextMenu}>
            <MoreHorizRounded fontSize="small" />
        </IconButton>
    )
}

export const UserRoleManagementList = () => {
    const app = useAppContext()
    const userList = useGetUserListQuery()

    const onRowClicked = (event, row) => {
        console.log("evever", row);
        const contextMenuData = [
            // {
            //     id : 1,
            //     title : "Edit Profile",
            //     onClick : () => app.editUserProfileModalRef.current?.open(row.original)
            // },
            {
                id : 1,
                title : "Change Role",
                onClick : () => app.editUserRoleModalRef?.current?.open(row)
            },
            {
                id : 1,
                title : "Mark as Inactive"
            }
        ]
        app.contextMenuRef?.current?.open({
            data : renderMenu(contextMenuData),
            top: event.clientY + 1,
            left : event.clientX + 1,
        })
    }


const columns = [
    columnHelper.accessor("id", {
        header : "Sr",
        cell : props => {
            return(
                <Text level='body-xs' sx={{padding: "0 12px 0 12px" }}>{props.row.index + 1}</Text>
            )
        },
        enableResizing : true,
        size: 48
    }),
    columnHelper.accessor("displayName", {
        header : "Name",
        cell : props => {
            console.log("pro", props);
            return(
                <Stack direction={"row"} alignItems={"center"}>
                    <Avatar src={props.row.original?.profilePicS3Url} alt={props.getValue()} size='sm' variant='soft' color='neutral' />
                    <div>
                        <Text sx={{ padding: "0 12px 0 12px" }}>{props.getValue()}</Text>
                        <Text level='body-xs' sx={{ padding: "0 12px 0 12px" }}>{props.row.original?.email}</Text>
                    </div>
                </Stack>
            )
        }
    }),
    columnHelper.accessor("phoneNumber", {
        header : "Phone",
        cell : props => {
            return(
                <Text sx={{ padding: "0 12px 0 12px" }}>{props.getValue()}</Text>
            )
        },
    }),
    columnHelper.accessor("userRole", {
        header : "Role",
        cell : props => {
            return(
                <Text level='body-xs' sx={{ padding: "0 12px 0 12px" }}>{props.getValue()}</Text>
            )
        },
    }),
    columnHelper.accessor("status", {
        header : "Status",
        cell : props => {
            return(
                <Chip variant="soft" size="sm" color="neutral">Active</Chip>
                // <Text level='body-xs' sx={{ padding: "0 12px 0 12px" }}>Active</Text>
            )
        },
    }),
    columnHelper.display({
        id: "actions",
        cell : props => {
            return(
                <IconButton size="sm" onClick={(event) => onRowClicked(event, props.row.original)}>
                    <MoreHorizRounded fontSize="small" />
                </IconButton>
            )
        }
    })
]

    return (
        <>
            <Table 
                data={userList.data?.data ?? []}
                loading={userList.isLoading}
                error={userList.isError}
                onRowClicked={onRowClicked}
                HeaderComponent={ListFilterView}
                showFooter
                rowHeight={56}
                columns={columns} />
        </>
    )
}

export default UserRoleManagementList