


import { Box, Sheet, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'
import { useMemo } from 'react'

const StagingServerAlert = () => {
    
    const isProduction = useMemo(() => {
        const production = "https://api.fairdeal.market"
        return process.env.REACT_APP_SERVER_URL == production
    }, [])

    if (isProduction) return null

    return (
        <Sheet variant='solid' color='danger' invertedColors sx={{ height: 32 }}>
            <Stack justifyContent={"center"} flex={1}>
                <Text textAlign={"center"}>Warning : You are using test server. We do not gurantee your data persistency.</Text>
            </Stack>
        </Sheet>
    )
}

export default StagingServerAlert