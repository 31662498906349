


import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar, Chip, List, Sheet, Stack, Table as MuiTable, CircularProgress, Box } from '@mui/joy'
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import Footer from './Footer'
import { rankItem } from '@tanstack/match-sorter-utils'
import Placeholder from './Placeholder'
import { useBeforeUnload, useLocation, useSearchParams } from 'react-router-dom'

const tableProps = {
  loading : false,
  controlled : true,
  initialState : {},
  data: [],
  rowHeight: 0,
  columns: [],
  showFooter: true,
  onContextMenu: () => null,
  HeaderComponent: React.Component
}

const globalFilterFn = (row, columnId, value, addMeta) => {
  const string = JSON.stringify(row)?.toLowerCase()
  return string.includes(value?.toLowerCase())
}

const Table = React.memo((props = tableProps) => {
  const [searchParam, setSearchParam] = useSearchParams()

  const state = useMemo(() => {
    console.log("mmemmda", searchParam.get("index"));
    return {
      search : searchParam.get("search") ?? "",
      pageIndex :  searchParam.get("pageIndex") ? parseInt(searchParam.get("pageIndex")) : 0,
      pageSize : 10
    }
  }, [searchParam])

  const updateState = (data) => {
    setSearchParam({...state, ...data}, {replace: true})
  }

  const onPagination = (fn) => {
    setSearchParam(fn(state), {replace: true})
    if(props.onStateChange){
      props.onStateChange(fn(state))
    }
  }

  const table = useReactTable({
    data: props.data,
    pageCount : props.pageCount,
    columns: props.columns,
    state: {
      pagination : state,
      globalFilter: state.search
    },
    globalFilterFn: globalFilterFn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel : getFilteredRowModel(),
    manualFiltering : props.hasOwnProperty("controlled") ? true : false,
    onPaginationChange: onPagination,
    manualPagination : props.hasOwnProperty("controlled") ? true : false,
    getPaginationRowModel: getPaginationRowModel(),
  })

  const onContextMenu = (event, rowData) => {
    if(props.onRowClicked){
      event.preventDefault()
      props.onRowClicked(event, rowData.original)
    }
  }

  return (
    <>
      {
        props.HeaderComponent && <props.HeaderComponent table={table} search={state.search} setSearch={value => updateState({...state, search : value})} />
      }
      {/* <Sheet variant='outlined' sx={{ borderRadius: 6, overflow: "hidden" }} style={{height: props.rowHeight ? props.rowHeight * 10 : "500px"}}> */}
        <MuiTable 
          variant='outlined' 
          borderAxis='xBetween' 
          hoverRow 
          stickyHeader
          stickyFooter
          size='sm'
          sx={{
            height: 100,
            borderRadius: "md",
            overflow: "hidden",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            "th" : {height: "40px", verticalAlign: "middle !important", backgroundColor : "#f5f8fd", px: 1.5},
            "td" : {px : 1.5},
            "tr" : {backgroundColor: "#fff"},
            "tr:hover" : {backgroundColor: "rgba(0,0,0,0.05) !important"}
          }}
          >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return ((
                    <th key={header.id} style={{ width: header.getSize(), fontSize: 14 }}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  )
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody style={{
            height: 100,
            overflow: "hidden"
          }}>
            {
              props.loading || props.error ?
              <Placeholder loading={props.loading} error={props.error} columns={props.columns} />
              :
              table.getRowModel().rows.map(row => (
                <tr 
                  key={row.id} 
                  onContextMenu={e => onContextMenu(e, row)}
                  style={{height: props.rowHeight ?? "40px" }}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} style={{fontSize: "13px"}}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            }
          </tbody>
        </MuiTable>
      {/* </Sheet> */}
      {
        !(props.showFooter == false) && props.hasOwnProperty("showFooter") && <Footer table={table} />
      }
    </>
  )
})

export default Table