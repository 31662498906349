


import { Box, Stack } from '@mui/joy'
import React from 'react'
import Text from '../Text'
import GalleryImage from '../GalleryView/GalleryImage'

const StoreBrandByCategories = ({data}) => {
    if(data.length == 0){
        return(
            <Stack>
                <Text level='title-sm'>No Images Uploaded.</Text>
            </Stack>
        )
    }
    return data?.map(item => (
        <Box pb={2}>
            <Text level='title-md' pb={1}>{item?.productCategoryName}</Text>
            <Stack flexDirection={"row"} gap={2}>
                {
                    item?.images?.map(item => (
                        <GalleryImage src={item} style={{width: "64px", aspectRatio: 1, borderRadius: 3}} />
                    ))
                }
            </Stack>
        </Box>
    ))
}

export default StoreBrandByCategories