

import { Stack } from '@mui/joy'
import React from 'react'
import { OrderDetail } from '../../components/Order'
import Text from '../../components/Text'
import { useParams } from 'react-router-dom'

const OrderDetailPage = () => {
  const param = useParams()
  return (
    <Stack p={3}>
      <Stack flexDirection={"row"} pb={2}>
        <Stack flex={1}>
          <Text level='h3'>Order Detail</Text>
        </Stack>
        {/* <Button onClick={addNewBrand} startDecorator={<AddRounded fontSize='small' />}>Add Brand</Button> */}
      </Stack>
      <OrderDetail orderId={param.orderId} />
    </Stack>
  )
}

export default OrderDetailPage