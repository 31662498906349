
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Store from "."
import { REHYDRATE } from "redux-persist"
import Axios from "../utils/axios"
import axios from "axios"
import { isShopOpen } from "../utils"


const getRequestId = () => "1234-" + Date.now()


export const query = createApi({
    baseQuery : fetchBaseQuery({
        baseUrl : process.env.REACT_APP_SERVER_URL,
        method : "GET",
        prepareHeaders : (header) => {
            header.set("X-Auth-Token", Store.getState().auth.token)
            header.set("X-FDM-Request-Id", getRequestId())
            return header
        },
        headers : {
            "Content-Type" : "application/json",
        },
    }),
    reducerPath : "api",
    tagTypes : ["user"],
    // extractRehydrationInfo : (action, { reducerPath }) => {
    //   if (isHydrateAction(action)) {
    //     // when persisting the api reducer
    //     if (action.key === 'key used with redux-persist') {
    //       return action.payload
    //     }
    //     // When persisting the root reducer
    //     return action.payload[query.reducerPath]
    //   }
    // },
    endpoints :  builder => ({
        getUserRole : builder.query({
            queryFn : () => ({data: ["ADMIN", "BUYER", "SELLER", "DEFAULT", "DCA"]})
        }),
        getBrand : builder.query({
            query : () => `/brand`,
            providesTags : [{type : "getBrandList"}]
        }),
        getBrandInfo : builder.query({
            query : (brandId) => `/brand/${brandId}`
        }),
        getNearbyPlace : builder.query({
            query : () => `/landmarkType`
        }),
        getProductCategory : builder.query({
            query : () => `/productCategory`,
            transformResponse : (response) => {
                response.forEach(item => {
                    if(item.parentProductCategory){
                        const parentProductCategory = response?.find(it => it.id == item.parentProductCategory.id)
                        item.parentProductCategory = parentProductCategory ? {...parentProductCategory} : null
                    }
                })
                return response
            }
        }),
        getShopPaymentMethod : builder.query({
            queryFn : () => ({data: [
                { image: require("../assets/images/upi.png"), id: 'Upi' },
                { image: require("../assets/images/paytm.png"), id: 'Paytm' },
                { image: require("../assets/images/phonepe.png"), id: 'PhonePay'},
                { image: require("../assets/images/gpay.png"), id: 'GooglePay'},
                { image: require("../assets/images/cash-on-delivery.png"), id: 'Cod' },
                { image: require("../assets/images/debit-card.png"), id: 'Card'}
            ]})
        }),
        getUserList : builder.query({
            query : () => ({
                url: '/user/getUsers'+"?size=100&page=0",
                method: "POST"
            }),
            providesTags : [{type : "getUserList"}]
        }),
        getStoreSkuActivity : builder.query({
            query : () => '/retailerProductImageActivity',
            providesTags : [{type : "getStoreSkuActivity"}]
        }),
        getUserInfo : builder.query({
            query : (id) => {
                if(id) return `/user/getUsers`
                return `/user`
            },
            transformResponse : (response, meta) => {
                return response
            },
            providesTags : [{type : "getUserInfo"}]
        }),
        getEmployeeInfo : builder.query({
            query : (id) => `/user/getUsers`,
            transformResponse : (response, meta) => {
                return response
            },
            providesTags : [{type : "getUserInfo"}]
        }),
        getStoreList : builder.query({
            query : (filter) => `/retailer?size=10` + filter,
            providesTags : (data) => {
                return [{type : "getStoreList"}]
            }
        }),
        getStoreListWithTaggedBrand : builder.query({
            query : (filter) => `/retailerActivityReportData?size=200&` + filter,
            providesTags : (data) => {
                return [{type : "getStoreList"}]
            }
        }),
        getOrderList : builder.query({
            query : (filter) => `/order/listOrders`+"?"+filter,
            providesTags : (data) => {
                return [{type : "getOrderList"}]
            }
        }),
        getOrderDetail : builder.query({
            query : (orderId) => `/order/${orderId}`,
            providesTags : (data) => {
                return [{type : "getOrderDetail"}]
            }
        }),
        getBeatList : builder.query({
            query : (filter) => `/beat/listBeats?&size=10` + filter,
            providesTags : (data) => {
                return [{type : "getBeatList"}]
            }
        }),
        getStoreInfo : builder.query({
            query : (retailId) => `/retailer/${retailId}`,
            transformResponse : async(response, baseQuery, retailId) => {
                const productCategory = await Store.dispatch(query.endpoints.getProductCategory.initiate())
                if(response){
                    if(response.productCategoryCategoryImages){
                        response.productCategoryCategoryImages?.forEach(item => {
                            const category = productCategory.data?.find(category => category.id == item.productCategoryId)
                            if(category){
                                item.productCategoryName = category.name
                            }
                        })
                    }
                    if(response.productCategoryCompressedImages){
                        response.productCategoryCompressedImages?.forEach(item => {
                            const category = productCategory.data?.find(category => category.id == item.productCategoryId)
                            if(category){
                                item.productCategoryName = category.name
                            }
                        })
                    }
                    response.isOpen = isShopOpen(response.operatingTime) 
                }
                return response
            }
        }),
        addUser : builder.mutation({
            query : (body) => ({
                url : "/user/add",
                method : "POST",
                body : body
            }),
            invalidatesTags : [{type : "getUserList"}]
        }),
        updateUserRole : builder.mutation({
            query : (body) => ({
                url : "/user/assignRole",
                method : "POST",
                body : body
            }),
            invalidatesTags : [{type : "getUserList"}]
        }),
        updateProfile : builder.mutation({
            query : (body) => ({
                url : "/user/update",
                method : "PATCH",
                body : body
            }),
            invalidatesTags : [{type : "getUserInfo"}]
        }),
        updateProfilePicture : builder.mutation({
            queryFn : async(body) => {
                const response = await Axios.patch("/user/updateProfilePic", body)
                return response.data
            },
            invalidatesTags : [{type : "getUserInfo"}]
        }),
        uploadUserListFile : builder.mutation({
            queryFn : async(body, param) => {
                const response = await Axios.post("/bulkUploadUsers", body)
                return true
            },
            invalidatesTags : [{type : "getUserList"}]
        }),
        uploadUserBeatMappingFile : builder.mutation({
            queryFn : async(body, param) => {
                const response = await Axios.post("/bulkUploadBeat", body)
                return true
            },
            // invalidatesTags : [{type : "getUserList"}]
        }),
        uploadRetailerListFile : builder.mutation({
            queryFn : async(body) => {
                const response = await Axios.post("/bulkUploadRetailers", body)
                return true
            },
            invalidatesTags : [{type : "getStoreList"}]
        }),
        updateBrand : builder.mutation({
            query : (body) => {
                if(body.id != "new") return { url : "/brand", method : "PATCH", body : body}
                delete body.id
                return { url : "/brand", method : "POST", body : body}
            },
            invalidatesTags : [{type : "getBrandList"}]
        }),
        updateSkuLabel : builder.mutation({
            query : (body) => ({ url : "/mapRetailerProductImageActivity", method : "POST", body : body}),
            invalidatesTags : [{type : "getStoreSkuActivity"}]
        }),
    })
})




export const {
    useGetShopPaymentMethodQuery,
    useGetUserRoleQuery,
    useGetUserListQuery,
    useGetUserInfoQuery,
    useGetEmployeeInfoQuery,
    useGetStoreListQuery,
    useGetStoreInfoQuery,
    useGetBrandQuery,
    useGetBrandInfoQuery,
    useGetNearbyPlaceQuery,
    useGetStoreSkuActivityQuery,
    useGetProductCategoryQuery,
    useGetOrderListQuery,
    useGetOrderDetailQuery,
    useGetBeatListQuery,

    
    useAddUserMutation,
    useUpdateUserRoleMutation,
    useUpdateProfileMutation,
    useUpdateProfilePictureMutation,
    useUpdateBrandMutation,
    useUpdateSkuLabelMutation
} = query